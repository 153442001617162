import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import userHelper from '../helpers/userHelper.js'
import mailchimpHelper from '../helpers/mailchimpHelper.js'
import entitlementHelper from '../helpers/entitlementHelper.js'
import { DEEPSAMPLER_SERVER } from '../config.js'
import { JsonViewer } from '@textea/json-viewer'

import {
    DataGridPro,
    GridActionsCellItem,
    GridToolbar,
} from '@mui/x-data-grid-pro'
import {
    Tooltip,
    Snackbar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Modal,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
    Box,
    Typography,
    TextareaAutosize,
    Divider,
} from '@mui/material'
import {
    AccountCircle,
    ArrowBack,
    Check,
    Close,
    PersonAdd,
    Send,
    TextSnippet,
} from '@mui/icons-material'
import * as _ from 'lodash'
import { isObject } from '../helpers/jsHelper.js'

const defaultToast = {
    open: false,
    message: '',
    severity: 'success',
}

const copyToClipboard = (str) => {
    // copy the emails as a csv
    navigator.clipboard.writeText(str)
}

const UserTool = () => {
    const [users, setUsers] = useState(null)
    const [toast, setToast] = useState(defaultToast)
    const [showAddUserModal, setShowAddUserModal] = useState(false)
    const [modalContents, setModalContents] = useState(null)
    const [totalUsers, setTotalUsers] = useState(0)
    const [selectedEmailAction, setSelectedEmailAction] = useState({
        email: null,
        type: '',
    })
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const [filterModel, setFilterModel] = useState({
        items: JSON.parse(localStorage.getItem('filters')) || [],
    })

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: itemsPerPage,
    })

    const [advancedFilter, setAdvancedFilter] = useState('')
    const [advancedFilterError, setAdvancedFilterError] = useState('')
    const [advancedFilterWarnings, setAdvancedFilterWarnings] = useState([])
    const [advancedFilterMongoQuery, setAdvancedFilterMongoQuery] = useState('')

    useEffect(() => {
        localStorage.setItem('filters', JSON.stringify(filterModel.items))
        onadvancedFilterChange({ target: { value: advancedFilter } })
    }, [filterModel])

    useEffect(() => {
        localStorage.setItem('pagination', JSON.stringify(paginationModel))
        handlePaginationModelChange(paginationModel)
    }, [paginationModel])

    useEffect(() => {
        const fetchData = async () => {
            console.log('in fetchData', paginationModel, filterModel)
            await fetchUsers(
                null,
                paginationModel.page,
                paginationModel.pageSize,
                filterModel
            )
        }

        fetchData()
    }, [])

    // Calculate the dynamic height for the DataGridPro component
    const calculateDataGridHeight = () => {
        // Adjust these values based on your layout's header, footer, and any other elements' heights
        const headerHeight = 64 // Example header height
        const padding = 32 // Total vertical padding around the DataGridPro
        const controlsHeight = 50 // Additional controls height if any below the DataGridPro

        // Calculate available height for the DataGridPro
        return `calc(100vh - ${headerHeight + padding + controlsHeight}px)`
    }

    const handlePaginationModelChange = (newModel) => {
        console.log('handlePaginationModelChange', newModel)
        setPaginationModel(newModel)
        // You may also want to fetch new data based on the new pagination model
        setItemsPerPage(newModel.pageSize)
        fetchUsers(null, newModel.page, newModel.pageSize)
    }

    // Fetch all users with filters and pagination
    const buildFilterQueryFromModel = (filterModel, advancedFilter) => {
        // Define a list of fields that are explicitly known to be boolean
        const booleanFields = [
            'hasPurchased',
            'isPartner',
            'appliedForLP',
            'fulfillmentSent',
        ] // Add more boolean field names as needed

        let query = null
        if (advancedFilter === null) {
            query = filterModel.items.reduce((acc, item) => {
                // Check if the field is known to be boolean and the value is 'true' or 'false'
                if (
                    booleanFields.includes(item.columnField) &&
                    (item.value.toLowerCase() === 'true' ||
                        item.value.toLowerCase() === 'false')
                ) {
                    acc[item.columnField] = item.value.toLowerCase() === 'true'
                } else if (typeof item.value === 'string') {
                    // Use a regex pattern to create a fuzzy match for strings
                    acc[item.columnField] = {
                        $regex: item.value,
                        $options: 'i',
                    } // 'i' for case-insensitive
                } else {
                    // For non-string fields, use the exact match
                    acc[item.columnField] = item.value
                }
                return acc
            }, {})
        } else {
            query = filterModel.items.map((item) => {
                let pair = {}
                if (
                    booleanFields.includes(item.columnField) &&
                    (item.value.toLowerCase() === 'true' ||
                        item.value.toLowerCase() === 'false')
                ) {
                    pair[item.columnField] = item.value.toLowerCase() === 'true'
                } else if (typeof item.value === 'string') {
                    pair[item.columnField] = {
                        $regex: item.value,
                        $options: 'i',
                    }
                } else {
                    pair[item.columnField] = item.value
                }
                return pair
            })
        }

        if (advancedFilter !== null) {
            advancedFilter = [advancedFilter]
            query = { $and: [...query, ...advancedFilter] }
        }
        console.log(
            JSON.stringify(
                Object.keys(query).length === 0 ? {} : query,
                null,
                2
            )
        )
        // Ensure the query is not an empty string, which would cause an error in MongoDB
        return Object.keys(query).length === 0 ? {} : query
    }

    async function handleSendFulfillmentEmail(email, emailType) {
        // we need to check if the user has a legacy license key, or an active entitlement for emergent drums
        // if yes, we should send the current license key
        // if no, we need to create a new entitlement and send that license key

        console.log(
            `handleSendFulfillmentEmail: email: ${email}, emailType: ${emailType}`
        )

        const fulfillmentTemplateByEmailType = {
            ed: 'purchase_fulfillment',
            ed_lp: 'launch_partner_fulfillment',
            hu: 'humanize_purchase_fulfillment',
            ed_trial: 'ed_free_trial_fulfillment',
            ip: 'infinite_packs_purchase_fulfillment',
            ip_lp: 'infinite_packs_launch_partner_fulfillment',
            ae_sub_monthly: 'audialab_everything_sub_fulfillment',
            ae_sub_yearly: 'audialab_everything_sub_fulfillment',
            ae_bundle: 'audialab_everything_bundle_fulfillment',
            ds2_pro: 'ds2_pro_fulfillment',
            ds2prorc: 'ds2_pro_pre_release_fulfillment',
        }
        const appInfoByEmailType = {
            ed: { appName: 'ed' },
            ed_lp: { appName: 'ed' },
            ed_trial: {
                appName: 'ed',
                subType: 'trial',
            },
            hu: { appName: 'hu' },
            ip: { appName: 'ip' },
            ip_lp: { appName: 'ip' },
            ae_sub_monthly: { appName: 'ae', subType: 'sub_monthly' },
            ae_sub_yearly: { appName: 'ae', subType: 'sub_yearly' },
            ae_bundle: { appName: 'ae', subType: 'bundle' },
            ds2_pro: { appName: 'ds2', subType: 'pro' },
            ds2prorc: { appName: 'ds2', subType: 'pro' },
        }

        let templateName = fulfillmentTemplateByEmailType[emailType]
        console.log(`[Debug] Selected template name: ${templateName}`)
        let entitlementData = appInfoByEmailType[emailType]
        console.log(
            `[Debug] Entitlement data retrieved: ${JSON.stringify(
                entitlementData
            )}`
        )
        let { appName, needsExpiration, validDurationHours, subType } =
            entitlementData

        // check if user has a current license key
        console.log(`[Debug] Fetching user data for email: ${email}`)
        const user = await userHelper.getSingleUser({ email })

        console.log(`[Debug] User data retrieved: `, user)

        // active entitlements where appName is "ed"
        console.log(
            `[Debug] Filtering active entitlements for appName: ${appName}`
        )

        // const existingEntitlementsForAppName = user.entitlements.filter(
        //     (entitlement) =>
        //         entitlement &&
        //         entitlement.appName === appName &&
        //         entitlement.active &&
        //         ((!entitlement.subType && !subType) ||
        //             entitlement.subType === subType) &&
        //         // licenseKey is not null or empty string
        //         entitlement.licenseKey
        // )

        // console.log(
        //     `[Debug] Existing entitlements for appName ${appName}: ${existingEntitlementsForAppName.length}`
        // )

        let licenseKeyForEmail

        // if (existingEntitlementsForAppName.length > 0 && false) {
        //     // short circuit this, dont reuse existing license keys
        //     licenseKeyForEmail = existingEntitlementsForAppName[0].licenseKey // use the first existing license key
        //     console.log(
        //         `[Debug] Found existing license key for user ${email}: ${licenseKeyForEmail}`
        //     )
        // }
        if (licenseKeyForEmail) {
            console.log(
                `[Debug] User ${email} has a license key, using that: ${licenseKeyForEmail}`
            )
        } else {
            // user doesn't have a license key, so we need to create one
            console.log(
                `[Debug] User ${email} doesn't have a license key, creating one...`
            )

            try {
                let entitlement =
                    await entitlementHelper.upsertEntitlementOnUserByEmail({
                        email,
                        appName,
                        ...entitlementData,
                    })

                console.log(
                    `[Debug] Created new entitlement for user ${email}: ${JSON.stringify(
                        entitlement
                    )}`
                )
                console.log(
                    `[Debug] Setting licenseKeyForEmail to ${entitlement.licenseKey}`
                )

                licenseKeyForEmail = entitlement.licenseKey
            } catch (error) {
                console.error(`[Debug] Error in creating entitlement: `, error)
                setToast({
                    open: true,
                    message: error.message,
                    severity: 'error',
                })
                return
            }
        }
        console.log(
            `[Debug] Sending fulfillment email '${templateName}' to ${email} with license key ${licenseKeyForEmail}`
        )

        await mailchimpHelper
            .sendFullfillmentEmail({
                email,
                licenseKey: licenseKeyForEmail,
                templateName,
                appName,
                subType,
            })
            .then(() => {
                console.log(
                    `fulfillment email '${templateName}' sent to ${email}`
                )
                setToast({
                    open: true,
                    message: `Fulfillment email sent to ${email}`,
                    severity: 'success',
                })
            })
            .then(() => {
                fetchUsers(email)
            })
            .catch((err) => {
                console.error(err)
                setToast({
                    open: true,
                    message: err.message,
                    severity: 'error',
                })
            })
    }

    const fetchUsers = async (
        specificUserEmail = null,
        page = 0,
        newItemsPerPage = paginationModel.pageSize,
        filter = filterModel,
        advancedFilter = null
    ) => {
        console.log(
            'fetchUsers',
            'specificUserEmail:',
            specificUserEmail,
            'page:',
            page,
            'newItemsPerPage:',
            newItemsPerPage,
            'filter:',
            filter
        )
        try {
            let fetchedUsers = []
            let totalUsersCount = 0

            if (specificUserEmail) {
                // Fetch a specific user's data
                const singleUser = await userHelper.getSingleUser({
                    email: specificUserEmail,
                })

                if (singleUser) {
                    console.log('found singleUser:', singleUser)
                    fetchedUsers = users.map((user) =>
                        user.email === singleUser.email ? singleUser : user
                    )
                }
                console.log('fetchUsers:', fetchUsers)
                totalUsersCount = users.length
            } else {
                const filterQuery = buildFilterQueryFromModel(
                    filter,
                    advancedFilter
                )
                const response = await userHelper.getUsers({
                    populateFields: ['lastSessionRef'],
                    page,
                    limit: newItemsPerPage,
                    filter: filterQuery,
                })

                if (response && response.users) {
                    fetchedUsers = response.users
                    totalUsersCount = response.totalUsers
                } else {
                    throw new Error('Invalid response structure from API')
                }
            }

            // Process and update the users state
            let updatedUsers = fetchedUsers.map((user) => {
                // add field for last session date
                if (user.lastSessionRef) {
                    user.lastSessionDate = user.lastSessionRef.createdAt
                    user.lastSessionString = JSON.stringify(user.lastSessionRef)
                    user.pluginVersion =
                        user.lastSessionRef?.sessionData?.sessionConfig?.pluginVersion
                } else {
                    user.lastSessionDate = '-'
                }
                user.userString = JSON.stringify(user)
                user.partnerDetails = user?.partnerDetails || {}
                user.appliedForLP =
                    'approved' in user?.partnerDetails ? true : false

                // search the entitlements array and find all the active entitlements
                user.activeEntitlements = _.filter(
                    user.entitlements,
                    (entitlement) => {
                        if (!entitlement) {
                            return false
                        }

                        if (entitlement.active) {
                            return entitlement
                        }
                    }
                )

                user.fulfillmentSent =
                    user.fulfillmentSent || user.userDetails?.fulfillmentSent

                // number of entitlements where active == true
                user.numActiveEntitlements = user.activeEntitlements.length

                return user
            })

            setUsers(updatedUsers)
            setTotalUsers(totalUsersCount)
            console.log({ updatedUsers })
            console.log({ totalUsersCount })
        } catch (error) {
            console.error('Error fetching users:', error)
            setToast({
                open: true,
                message: error.message || 'An unexpected error occurred',
                severity: 'error',
            })
        }
    }

    const renderBoolean = ({ value }) =>
        value ? (
            <Check size="small" color="success" />
        ) : (
            <Close size="small" color="disabled" />
        )

    const onadvancedFilterApply = () => {
        let advFilter = null
        setAdvancedFilterError('')
        try {
            advFilter = JSON.parse(advancedFilter)
        } catch (err) {
            setAdvancedFilterError(`JSON.parse failed: ${err.message}`)
        }

        let filterQuery = null
        try {
            filterQuery = buildFilterQueryFromModel(filterModel, advFilter)
            setAdvancedFilterMongoQuery(JSON.stringify(filterQuery, null, 2))
        } catch (err) {}

        let isWarning = false
        const warnings = []
        function validateFilterQuery(filterQuery) {
            function validatePair(key, val) {
                if (!key.startsWith('$') && !key.startsWith('userDetails')) {
                    isWarning = true
                    warnings.push(
                        `Warning: key '${key}' does not start with 'userDetails.', you are filtering at the top level :-)`
                    )
                }
                validateFilterQuery(val)
            }
            if (isObject(filterQuery)) {
                for (let key in filterQuery) {
                    validatePair(key, filterQuery[key])
                }
            } else if (Array.isArray(filterQuery)) {
                for (let i = 0; i < filterQuery.length; i++) {
                    validateFilterQuery(filterQuery[i])
                }
            }
        }
        validateFilterQuery(advFilter)
        if (isWarning) {
            setAdvancedFilterWarnings(warnings)
        } else {
            setAdvancedFilterWarnings([])
        }

        const fetchData = async () => {
            console.log('in fetchData', paginationModel, filterModel)
            await fetchUsers(
                null,
                paginationModel.page,
                paginationModel.pageSize,
                filterModel,
                advFilter
            )
        }

        fetchData()
    }

    const onadvancedFilterChange = (event) => {
        setAdvancedFilter(event.target.value)
    }

    const onUserDetailsReset = () => {
        setAdvancedFilterError('')
        setAdvancedFilter('')
        setAdvancedFilterMongoQuery('')
        const fetchData = async () => {
            console.log('in fetchData', paginationModel, filterModel)
            await fetchUsers(
                null,
                paginationModel.page,
                paginationModel.pageSize,
                filterModel
            )
        }

        fetchData()
    }

    const columns = [
        { headerName: 'Email', field: 'email', flex: 1 },
        {
            headerName: 'First name',
            field: 'firstName',
            minWidth: 80,
            flex: 0.5,
        },
        {
            headerName: 'Last name',
            field: 'lastName',
            minWidth: 80,
            flex: 0.5,
        },
        {
            headerName: 'Version Used',
            field: 'pluginVersion',
            flex: 0.3,
        },
        {
            headerName: 'Purchased',
            field: 'hasPurchased',
            flex: 0.25,
            type: 'boolean',
            renderCell: renderBoolean,
        },
        {
            headerName: 'Partner',
            field: 'isPartner',
            flex: 0.25,
            type: 'boolean',
            renderCell: renderBoolean,
        },
        {
            headerName: 'Applied LP',
            field: 'appliedForLP',
            flex: 0.25,
            type: 'boolean',
            renderCell: renderBoolean,
        },
        {
            headerName: 'Sent License',
            field: 'fulfillmentSent',
            flex: 0.3,
            type: 'boolean',
            renderCell: renderBoolean,
        },
        {
            headerName: 'DS Link',
            flex: 0.4,
            field: 'activeEntitlements',
            renderCell: (params) => {
                // check if they have a "ds" entitlement
                // if they do, show a "copy ds link" button
                // if they don't, show a "generate ds link" button
                let email = params.row.email
                let dsEntitlement = _.find(params.value, (entitlement) => {
                    if (entitlement.appName === 'ds') {
                        return entitlement
                    }
                })

                if (dsEntitlement) {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                console.log(email)
                                // https://audialab.com/ds?email={user email}&licenseKey={license key}
                                // but url encode the email address

                                copyToClipboard(
                                    `${DEEPSAMPLER_SERVER}?e=${encodeURIComponent(
                                        email
                                    )}&lk=${dsEntitlement.licenseKey}`
                                )
                            }}
                        >
                            Copy
                        </Button>
                    )
                } else {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                                let entitlement =
                                    await entitlementHelper.upsertEntitlementOnUserByEmail(
                                        {
                                            email,
                                            appName: 'ds',
                                        }
                                    )
                                console.log(
                                    `created entitlement ${JSON.stringify(
                                        entitlement
                                    )} for ${email}`
                                )
                                fetchUsers(email)
                            }}
                        >
                            Add DS
                        </Button>
                    )
                }
            },
        },
        { headerName: '# Active Entitlements', field: 'numActiveEntitlements' },
        {
            headerName: 'Date added',
            field: 'createdAt',
            flex: 0.6,
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            headerName: 'Active Entitlements',
            field: 'entitlements',
            flex: 0.3,
            renderCell: ({ value }) => {
                // show a list of names and license keys
                // one on each line
                // emergent drums should be shortend to "ED"
                // and day pass should be "DP"
                // open a modal with the full list
                let activeEntitlements = _.filter(value, (entitlement) => {
                    // if the entitlement is null, skip it
                    if (!entitlement) {
                        return false
                    }

                    if (entitlement.active) {
                        return entitlement
                    }
                })

                let entitlementsStringArray = (activeEntitlements || []).map(
                    (entitlement) => {
                        let name = entitlement.name
                        if (name === 'Emergent Drums') {
                            name = 'ED'
                        } else if (name === 'Day Pass') {
                            name = 'DP'
                        }
                        return `${name}: ${entitlement.licenseKey} (createdAt: ${entitlement.createdAt}, expires: ${entitlement.expiresAt}})`
                    }
                )

                // join the array into a string
                let entitlementsString = entitlementsStringArray
                    .sort()
                    .join('\n')

                return (
                    activeEntitlements && (
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                    setModalContents(
                                        <Box>{entitlementsString}</Box>
                                    )
                                    // log the entitlements to the console
                                    console.log(value)

                                    setToast({
                                        open: true,
                                        message: `All entitlements logged to console`,
                                        severity: 'info',
                                    })
                                }}
                            >
                                <TextSnippet fontSize="inherit" />
                            </IconButton>
                        </Box>
                    )
                )
            },
        },
        {
            headerName: 'Last Session Date',
            field: 'lastSessionDate',
            flex: 0.6,
            type: 'dateTime',
            valueFormatter: ({ value }) => {
                return value !== '-' ? new Date(value) : ''
            },
        },
        {
            headerName: 'Last Session',
            field: 'lastSessionString',
            flex: 0.3,
            renderCell: ({ value }) => {
                // show a button that opens a modal with the user object text
                return (
                    value && (
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                    setModalContents(
                                        <JsonViewer
                                            value={JSON.parse(value)}
                                            enableClipboard={false}
                                            editable={false}
                                            quotesOnKeys={false}
                                            displayDataTypes={false}
                                            style={{
                                                maxHeight: '500px',
                                                overflowY: 'scroll',
                                            }}
                                        />
                                    )
                                    setToast({
                                        open: true,
                                        message: `Last session logged to console`,
                                        severity: 'success',
                                    })
                                    console.log(JSON.parse(value))
                                }}
                            >
                                <TextSnippet fontSize="inherit" />
                            </IconButton>
                        </Box>
                    )
                )
            },
        },
        {
            headerName: 'User Object',
            field: 'userString',
            flex: 0.3,
            renderCell: ({ value }) => {
                // show a button that opens a modal with the user object text
                return (
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                                setModalContents(
                                    <JsonViewer
                                        value={JSON.parse(value)}
                                        enableClipboard={false}
                                        editable={false}
                                        quotesOnKeys={false}
                                        displayDataTypes={false}
                                        style={{
                                            maxHeight: '700px',
                                            maxWidth: '700px',
                                            overflowY: 'scroll',
                                        }}
                                    />
                                )
                                setToast({
                                    open: true,
                                    message: `User object logged to console`,
                                    severity: 'success',
                                })
                                console.log(JSON.parse(value))
                            }}
                        >
                            <AccountCircle fontSize="inherit" />
                        </IconButton>
                    </Box>
                )
            },
        },

        {
            headerName: 'Actions',
            field: 'actions',
            flex: 1,
            renderCell: (params) => {
                const emailActionType =
                    selectedEmailAction.email === params.row.email
                        ? selectedEmailAction.type
                        : ''
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            gap: 1,
                        }}
                    >
                        <FormControl
                            variant="standard"
                            sx={{
                                minWidth: 150,
                                maxWidth: 'calc(100% - 40px)',
                            }}
                        >
                            <InputLabel id="select-email-action-label">
                                Select email type
                            </InputLabel>
                            <Select
                                size="small"
                                labelId="select-email-action-label"
                                id="select-email-action"
                                value={emailActionType}
                                onChange={(selection) => {
                                    setSelectedEmailAction({
                                        email: params.row.email,
                                        type: selection.target.value,
                                    })
                                }}
                            >
                                <MenuItem value={'ed'}>
                                    ED Purchase email
                                </MenuItem>
                                <MenuItem value={'ed_lp'}>
                                    Launch partner email
                                </MenuItem>
                                <MenuItem value={'hu'}>
                                    Humanize purchase email
                                </MenuItem>
                                <MenuItem value={'ip'}>
                                    IP purchase email
                                </MenuItem>
                                <MenuItem value={'ip_lp'}>
                                    IP Launch Partner email
                                </MenuItem>
                                <MenuItem value={'ed_trial'}>
                                    ED 7day trial email
                                </MenuItem>
                                <MenuItem value={'ae_sub_monthly'}>
                                    Everything Subscription monthly email
                                </MenuItem>
                                <MenuItem value={'ae_sub_yearly'}>
                                    Everything Subscription yearly email
                                </MenuItem>
                                <MenuItem value={'ae_bundle'}>
                                    Everything Bundle email
                                </MenuItem>
                                <MenuItem value={'ds2_pro'}>
                                    DS2 Pro Purchase email
                                </MenuItem>
                                <MenuItem value={'ds2prorc'}>
                                    DS2 Pro RC Build email
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <IconButton
                            aria-label="send email"
                            size="small"
                            color="primary"
                            onClick={async () => {
                                try {
                                    if (emailActionType) {
                                        await handleSendFulfillmentEmail(
                                            params.row.email,
                                            emailActionType
                                        )
                                        await fetchUsers(params.row.email)
                                    }
                                } catch (error) {
                                    console.error(error)
                                    // Handle the error appropriately in your application
                                }
                            }}
                        >
                            <Send fontSize="inherit" />
                        </IconButton>
                    </Box>
                )
            },
        },
    ]

    return (
        <Box sx={{ p: 2 }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                }}
            >
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Link to="/">
                        <Tooltip title="Go home">
                            <ArrowBack />
                        </Tooltip>
                    </Link>
                    <Typography variant="h5">User Tool</Typography>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PersonAdd />}
                    onClick={() => setShowAddUserModal(true)}
                >
                    Add user
                </Button>
            </Box>
            <Box
                sx={{
                    mb: 2,
                    ml: 2,
                }}
            >
                {/* button for refreshing the users */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setToast({
                            open: true,
                            message: `Refreshing users...`,
                            severity: 'info',
                        })
                        fetchUsers()
                    }}
                >
                    Refresh users
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        const emails = (users || []).map((user) => user.email)
                        // copy the emails as a csv
                        navigator.clipboard.writeText(emails.join(','))
                        setToast({
                            open: true,
                            message: `Copied ${emails.length} emails to clipboard`,
                            severity: 'success',
                        })
                    }}
                >
                    Copy emails to clipboard
                </Button>
            </Box>
            {/* modal that shows a user object string */}
            {modalContents && (
                <Modal
                    open={!!modalContents}
                    onClose={() => setModalContents(null)}
                >
                    <Box
                        sx={{
                            // centered
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            // white background
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            // set max height and width
                            maxHeight: '80vh',
                            maxWidth: '90vw',
                            // enable scroll if content is too big
                            overflow: 'auto',
                        }}
                    >
                        <pre>{modalContents}</pre>
                    </Box>
                </Modal>
            )}
            {users && (
                <Box sx={{ width: '100%', height: '1000px' }}>
                    <Box sx={{ mb: 1 }}>
                        <InputLabel htmlFor="advancedFilter">
                            Advanced Mongo Filter
                        </InputLabel>
                        <TextareaAutosize
                            name="advancedFilter"
                            minRows={5}
                            cols={100}
                            placeholder='{ "userDetails.userDetailsStringField": "value", "topLevelBooleanField": true }'
                            onChange={onadvancedFilterChange}
                            value={advancedFilter}
                        />
                        <Button onClick={onadvancedFilterApply}>Apply</Button>
                        <Button onClick={onUserDetailsReset}>Reset</Button>
                        {advancedFilterError !== '' ? (
                            <div
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    width: 'fit-content',
                                }}
                            >
                                {advancedFilterError}
                            </div>
                        ) : (
                            <span />
                        )}
                        {advancedFilterMongoQuery === '' ||
                        advancedFilterMongoQuery === '{}' ? (
                            <span />
                        ) : (
                            <div>
                                <div>Mongo Query:</div>
                                <div
                                    style={{
                                        backgroundColor: 'yellow',
                                        color: 'black',
                                        width: 'fit-content',
                                    }}
                                >
                                    {advancedFilterWarnings.map((warning) => (
                                        <div>{warning}</div>
                                    ))}
                                </div>
                                {advancedFilterMongoQuery === '{}' ? (
                                    <span />
                                ) : (
                                    <pre>{advancedFilterMongoQuery}</pre>
                                )}
                            </div>
                        )}
                    </Box>
                    <DataGridPro
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: paginationModel.pageSize,
                                    page: paginationModel.page,
                                },
                            },
                        }}
                        columns={columns}
                        rows={users}
                        getRowId={(row) => row._id}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        rowCount={totalUsers}
                        pagination
                        paginationMode="server"
                        onPageChange={(newPage) => {
                            console.log('onPageChange', newPage)
                            fetchUsers(null, newPage, paginationModel.pageSize)
                        }}
                        rowsPerPageOptions={[10, 100, 1000]}
                        filterModel={filterModel}
                        onFilterModelChange={(newModel) => {
                            console.log('onFilterModelChange', newModel)
                            setFilterModel(newModel)
                            fetchUsers(null, 0, itemsPerPage, newModel) // Fetch users with the new filter model
                        }}
                        components={{
                            Toolbar: GridToolbar,
                            // ActionCell: CustomActionCell,
                        }}
                        onPageSizeChange={(newPageSize) => {
                            console.log('onPageSizeChange', newPageSize)
                            setPaginationModel((prevModel) => ({
                                ...prevModel,
                                pageSize: newPageSize,
                            }))
                            setItemsPerPage(newPageSize)
                            fetchUsers(null, paginationModel.page, newPageSize)
                        }}
                    />
                </Box>
            )}
            {/* button that copies the email addresses into the clipboard */}

            <Snackbar
                open={toast.open}
                autoHideDuration={4000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                    setToast(defaultToast)
                }}
                message={toast.message}
                severity={toast.severity}
            />
            <AddNewUserDialog
                open={showAddUserModal}
                onClose={(success) => {
                    success && fetchUsers()
                    setShowAddUserModal(false)
                }}
                setToast={setToast}
            />
        </Box>
    )
}

function AddNewUserDialog({ open, onClose, setToast }) {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')

    function resetValues() {
        setFirstName('')
        setLastName('')
        setEmail('')
    }

    async function handleSubmit() {
        let response = await userHelper.registerUser({
            firstName,
            lastName,
            email,
        })

        if (response.status !== 200) {
            setToast({
                open: true,
                message: response.error,
                severity: 'error',
            })
            onClose()
        } else {
            setToast({
                open: true,
                message: `Added user with the email of ${email}`,
                severity: 'success',
            })
            resetValues()
            onClose(true)
        }
    }

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Add a user</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    autoFocus
                    id="firstName"
                    label="First name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="lastName"
                    label="Last name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="standard"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => {
                        onClose()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserTool
