import { FRONTEND_SERVER, BACKEND_SERVER } from '../config.js'
import baxios from '../baxios.js'
import axios from 'axios'
import stripe from 'stripe'

const NODE_ENV =
    process.env.NODE_ENV === 'production' ? 'production' : 'development'

const stripeHelper = {
    getProductById: async (productId) => {
        try {
            console.log(`Fetching Stripe product by ID: ${productId}`)
            if (!productId) {
                throw new Error('no productId provided to getProductById')
            }
            const stripeClient = stripe(process.env.STRIPE_SECRET_KEY)
            const response = await stripeClient.products.retrieve(productId)
            console.log(
                `Stripe product fetched successfully with ID: ${productId}. Product details:`,
                response
            )
            return response
        } catch (error) {
            console.error(
                `Error fetching Stripe product by ID: ${productId}`,
                error
            )
            return { error }
        }
    },
    createProduct: async (data) => {
        console.log('Attempting to create a Stripe product with data:', data)
        if (!data) {
            console.error('Error: no data provided to createProduct')
            throw new Error('no data provided to createProduct')
        }
        if (!data.name) {
            console.error('Error: no name provided to createProduct')
            throw new Error('no name provided to createProduct')
        }

        const stripeClient = stripe(process.env.STRIPE_SECRET_KEY)
        const response = await stripeClient.products.create(data)
        console.log(
            `Stripe product created successfully with ID: ${response.id}`
        )
        return response
    },
    deleteProduct: async (productId) => {
        console.log(`Attempting to delete Stripe product with ID: ${productId}`)
        if (!productId) {
            console.error('Error: no productId provided to deleteProduct')
            throw new Error('no productId provided to deleteProduct')
        }
        const stripeClient = stripe(process.env.STRIPE_SECRET_KEY)
        const response = await stripeClient.products.del(productId)
        console.log(
            `Stripe product with ID: ${productId} deleted successfully.`
        )
        return response
    },
    updateProduct: async (productId, data) => {
        console.log(
            `Attempting to update Stripe product with ID: ${productId} using data:`,
            data
        )
        if (!productId) {
            console.error('Error: no productId provided to updateProduct')
            throw new Error('no productId provided to updateProduct')
        }
        if (!data) {
            console.error('Error: no data provided to updateProduct')
            throw new Error('no data provided to updateProduct')
        }
        const stripeClient = stripe(process.env.STRIPE_SECRET_KEY)
        const response = await stripeClient.products.update(productId, data)
        console.log(
            `Stripe product with ID: ${productId} updated successfully.`
        )
        return response
    },
    getProductSchema: async () => {
        console.log('Fetching Stripe product schema')
        try {
            const response = await baxios.get(`/stripe/products/schema`)
            console.log('Stripe product schema fetched successfully:', response)
            return response.data
        } catch (error) {
            console.error(
                'Error fetching Stripe product schema:',
                error.message
            )
            throw new Error('Error fetching Stripe product schema')
        }
    },
    getStripePriceInfoFromPriceId: async function (priceId) {
        try {
            console.log(`Getting price info for priceId: ${priceId}`)
            console.log(`${BACKEND_SERVER}/stripe/get-price/${priceId}`)
            const response = await axios.get(
                `${BACKEND_SERVER}/stripe/get-price/${priceId}`
            )
            console.log(`price => ${JSON.stringify(response.data)}`)
            return response.data.price
        } catch (err) {
            console.log(`error getting price: ${err}`)
            return { error: err }
        }
    },
    createStripePrice: async function (productId, priceData) {
        console.log(`Creating new Stripe price for product ID: ${productId}`)
        try {
            const response = await baxios.post(
                `/stripe/create-price/${productId}`,
                priceData
            )
            console.log('New Stripe price created:', response.data)
            return response.data
        } catch (error) {
            console.error('Error creating new Stripe price:', error.message)
            throw error
        }
    },
    getStripePricesForProduct: async function (productId) {
        console.log(`Fetching Stripe prices for product ID: ${productId}`)
        try {
            const response = await axios.get(
                `${BACKEND_SERVER}/stripe/get-prices/${productId}`
            )
            console.log('Stripe prices fetched:', response.data.prices)
            return response.data.prices
        } catch (error) {
            console.error('Error fetching Stripe prices:', error.message)
            throw error
        }
    },
}

export default stripeHelper
