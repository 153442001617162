import {
    AudiotrackSharp,
    CheckBox,
    CheckBoxOutlineBlank,
} from '@mui/icons-material'
import {
    Autocomplete,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    FormGroup,
    TextField,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { useEffect, useRef, useState } from 'react'
import { WaveForm, WaveSurfer } from 'wavesurfer-react'
import { dbSampleHelper } from '../helpers/dbSampleHelper.js'
import { generateWav, getFilesFromZip } from '../helpers/wavHelper.js'

let audioCtx = new (window.AudioContext || window.webkitAudioContext)()
let source
let gainNode
const defaultTags = [
    'kick',
    'snare',
    'bass',
    'hi-hat',
    'open hi-hat',
    'closed hi-hat',
    '808',
    'clap',
    'crash',
    'fill',
    'rimshot',
    'tom',
    'glitch',
    'noise',
]

export default function SoundTagCard({
    setToast,
    sound = { tags: [] },
    modelId,
    setModelId,
}) {
    const [latentString, setLatentString] = useState(sound?.latentString)
    const [wav, setWav] = useState()
    const [tags, setTags] = useState(sound.tags)
    const [isSaving, setIsSaving] = useState(false)
    const soundRef = useRef()

    useEffect(() => {
        generateSound(!sound._id)
    }, [])

    useEffect(() => {
        soundRef?.current.loadDecodedBuffer(wav)
    }, [wav])

    async function generateSound(random = false) {
        soundRef?.current.stop()
        setIsSaving(true)
        const wavZip = await generateWav({
            latentString: random ? undefined : latentString && latentString,
            random: random ? random : !latentString,
            unitSampleRadius: random ? 0.05 : 0,
            modelId,
        })
        console.log({ wavZip })
        getFilesFromZip(wavZip, audioCtx, setWav, setLatentString, setModelId)
        random && setTags([])
        setIsSaving(false)
    }

    function handleWaveSurferMount(ws) {
        if (ws) {
            soundRef.current = ws
        }
        let wf = soundRef.current
        if (wf && wav) {
            wf.loadDecodedBuffer(wav)
        }
    }

    function playWav() {
        const context = soundRef.current.backend.ac
        source = context.createBufferSource()
        gainNode = context.createGain()
        source.connect(gainNode)
        source.buffer = wav
        gainNode.gain.value = 1
        soundRef.current.backend.setFilter(gainNode)
        soundRef.current.play(0)
    }

    function handleSaveTags() {
        setIsSaving(true)
        if (sound._id) {
            if (tags.length > 0) {
                dbSampleHelper
                    .replaceTagsOnSample(sound._id, tags)
                    .then((response) => {
                        setIsSaving(false)
                        setToast({
                            open: true,
                            message: `Updated tags for ${sound._id}`,
                            severity: 'success',
                        })
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            } else {
                dbSampleHelper
                    .deleteSampleById(sound._id)
                    .then((response) => {
                        setIsSaving(false)
                        setToast({
                            open: true,
                            message: `Removed ${sound._id}`,
                            severity: 'success',
                        })
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        } else {
            if (tags.length > 0) {
                console.log('saving tags on a new sound')
                dbSampleHelper
                    .saveSample({
                        latentString: latentString,
                        modelId,
                        tags,
                    })
                    .then((response) => {
                        generateSound(true)
                        setIsSaving(false)
                        setToast({
                            open: true,
                            message: `Saved tags to ${sound._id}`,
                            severity: 'success',
                        })
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            } else {
                setIsSaving(false)
            }
        }
    }

    return (
        <Card sx={{ width: 232 }}>
            <CardMedia height={240} width={240} onClick={() => playWav()}>
                <WaveSurfer onMount={handleWaveSurferMount}>
                    <WaveForm
                        id={`waveform-${sound._id ? sound._id : 'solo'}`}
                        hideCursor={true}
                        height={240}
                        interact={false}
                        audioContext={audioCtx}
                        backgroundColor="#2F2F34"
                        waveColor="#BAB9EB"
                        progressColor="#8A8AA3"
                        cursorColor="#ffffff"
                    />
                </WaveSurfer>
            </CardMedia>
            <CardContent>
                <FormGroup
                    sx={{
                        display: 'flex',
                        gap: '8px',
                    }}
                >
                    <Autocomplete
                        multiple
                        freeSolo
                        disableCloseOnSelect
                        id="tag-input"
                        size="small"
                        options={defaultTags}
                        value={tags}
                        onChange={(e, value) => {
                            setTags(value)
                        }}
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={
                                        <CheckBoxOutlineBlank fontSize="small" />
                                    }
                                    checkedIcon={<CheckBox fontSize="small" />}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Tags"
                                placeholder="Enter tag"
                            />
                        )}
                    />
                    <LoadingButton
                        variant="contained"
                        disableElevation
                        loading={isSaving}
                        onClick={handleSaveTags}
                    >
                        Save tags
                    </LoadingButton>
                    {!sound._id && (
                        <LoadingButton
                            variant="outlined"
                            loading={isSaving}
                            onClick={() => generateSound(true)}
                        >
                            New sound
                        </LoadingButton>
                    )}
                </FormGroup>
            </CardContent>
        </Card>
    )
}
