import { useRef, useState, useEffect } from 'react'
import { EditorView } from '@codemirror/view'
import { EditorState } from '@codemirror/state'
import { json } from '@codemirror/lang-json'
import { basicSetup } from 'codemirror'

const useEditor = (initialProduct) => {
    const editorRef = useRef(null)
    const editorInstanceRef = useRef(null)
    // const [hasUnsavedEdits, setHasUnsavedEdits] = useState(false)

    useEffect(() => {
        console.log(
            'useEditor.js - useEditor: useEffect [initialProduct] triggered'
        )
        if (editorRef.current) {
            initializeEditor(initialProduct)
        }
    }, [initialProduct])

    useEffect(() => {
        console.log(
            'useEditor.js - useEditor: useEffect [editorRef.current] triggered'
        )
        if (editorRef.current) {
            if (!editorInstanceRef.current) {
                console.log(
                    'useEditor.js - useEditor: editorRef.current is set and editorInstanceRef.current is not set, initializing editor with initialProduct:',
                    initialProduct
                )
                initializeEditor(initialProduct)
            } else {
                console.log(
                    'useEditor.js - useEditor: editorRef.current is set but editorInstanceRef.current is already initialized'
                )
            }
        } else {
            console.log(
                'useEditor.js - useEditor: editorRef.current is not set, skipping editor initialization'
            )
        }
    }, [editorRef.current])

    const initializeEditor = (product) => {
        console.log(
            'useEditor.js - useEditor: initializeEditor called with product:',
            product
        )
        if (editorInstanceRef.current) {
            console.log(
                'useEditor.js - useEditor: editorInstanceRef.current is already set, updating editor content'
            )
            updateEditorContent(product)
        } else {
            console.log(
                'useEditor.js - useEditor: creating new EditorState and EditorView'
            )
            const startState = EditorState.create({
                doc: product ? JSON.stringify(product, null, 2) : '{}',
                extensions: [
                    basicSetup,
                    json(),
                    // EditorView.updateListener.of((update) => {
                    //     if (update.docChanged) {
                    //         // console.log(
                    //         //     'useEditor.js - useEditor: document changed, setting hasUnsavedEdits to true'
                    //         // )
                    //         // setHasUnsavedEdits(true)
                    //     }
                    // }),
                ],
            })
            console.log(
                'useEditor.js - useEditor: initializing editorInstanceRef.current'
            )
            editorInstanceRef.current = new EditorView({
                state: startState,
                parent: editorRef.current,
            })
        }
    }

    const updateEditorContent = (product) => {
        console.log(
            'useEditor.js - useEditor: updateEditorContent called with product:',
            product
        )
        if (!editorInstanceRef.current) {
            console.log(
                'useEditor.js - useEditor: editorInstanceRef.current is not set, initializing editor'
            )
            initializeEditor(product)
            return
        }
        const docContent = product ? JSON.stringify(product, null, 2) : '{}'
        console.log(
            'useEditor.js - useEditor: creating transaction to update document content'
        )
        const transaction = editorInstanceRef.current.state.update({
            changes: {
                from: 0,
                to: editorInstanceRef.current.state.doc.length,
                insert: docContent,
            },
        })
        editorInstanceRef.current.dispatch(transaction)
    }

    return {
        editorRef,
        editorInstanceRef,
        // hasUnsavedEdits,
        // setHasUnsavedEdits,
        updateEditorContent,
        initializeEditor,
    }
}

export default useEditor
