import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import userHelper from '../helpers/userHelper.js'
import mailchimpHelper from '../helpers/mailchimpHelper.js'
import configHelper from '../helpers/configHelper.js'

import bcryptjs from 'bcryptjs'
import { Navigate, Outlet } from 'react-router-dom'

import {
    DataGridPro,
    GridActionsCellItem,
    GridToolbar,
} from '@mui/x-data-grid-pro'
import {
    Tooltip,
    Snackbar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Modal,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
} from '@mui/material'

import {
    AccountCircle,
    ArrowBack,
    Check,
    Close,
    PersonAdd,
    Send,
    TextSnippet,
} from '@mui/icons-material'
import * as _ from 'lodash'

import { useAuth } from '../hooks/useAuth.js'

const defaultToast = {
    open: false,
    message: '',
    severity: 'success',
}

const copyToClipboard = (str) => {
    // copy the emails as a csv
    navigator.clipboard.writeText(str)
}

// can use this function to generate a hash and set it on their user directly in mongo for now
const hashPassword = async (password) => {
    // This is the cost factor for hashing
    const saltRounds = 10
    const salt = await bcryptjs.genSalt(saltRounds)
    const hash = await bcryptjs.hash(password, salt)
    return hash
}

const Superuser = () => {
    const [admins, setAdmins] = useState([])
    const [configs, setConfigs] = useState([])
    const [toast, setToast] = useState(defaultToast)
    const [openDialog, setOpenDialog] = useState(false)
    const [currentParams, setCurrentParams] = useState({})
    const [currentId, setCurrentId] = useState(null)
    const [textFieldValue, setTextFieldValue] = useState('')

    const { user } = useAuth()

    const fetchAdmins = () => {
        console.log('fetchAdmins: start')
        userHelper.getUsers({ filter: { admin: true } }).then((res) => {
            console.log('fetchAdmins: response', res)
            setAdmins(res.users)
        })
    }

    const fetchConfigs = () => {
        console.log('fetchConfigs: start')
        configHelper.getAllConfigs().then((res) => {
            console.log('fetchConfigs: response', res)
            setConfigs(res.data)
        })
    }

    useEffect(() => {
        console.log('fetching admins')
        fetchAdmins()
        console.log('fetching configs')
        fetchConfigs()
    }, [])

    if (!user?.superuser) {
        return <Navigate to="/login" />
    }

    const adminColumns = [
        { headerName: 'Email', field: 'email', flex: 1 },
        {
            headerName: 'First name',
            field: 'firstName',
            minWidth: 80,
            flex: 0.5,
        },
        {
            headerName: 'Last name',
            field: 'lastName',
            minWidth: 80,
            flex: 0.5,
        },
        {
            // a checkbox for toggling admin status
            headerName: 'Admin?',
            field: 'admin',
            minWidth: 80,
            flex: 0.5,
            renderCell: ({ row }) => (
                <Tooltip title="Toggle admin status">
                    <IconButton
                        onClick={() => {
                            // toggle the admin status
                            userHelper
                                .updateUser(row.email, {
                                    admin: !row.admin,
                                })
                                .then((res) => {
                                    if (res.error) {
                                        setToast({
                                            open: true,
                                            message: `Error: ${res.error}`,
                                            severity: 'error',
                                        })
                                    } else {
                                        setToast({
                                            open: true,
                                            message: `Successfully updated admin status for ${row.email}`,
                                            severity: 'success',
                                        })
                                        fetchAdmins()
                                    }
                                })
                        }}
                    >
                        {row.admin ? <Check /> : <Close />}
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            headerName: 'Set Password',
            field: 'setPassword',
            flex: 0.3,
            renderCell: ({ row }) => (
                // text field for setting a user's password with a button to save it
                <Tooltip title="Set password">
                    <IconButton
                        onClick={() => {
                            // prompt the user for a new password
                            const newPlainTextPassword = prompt(
                                `Enter a new password for ${row.email}`
                            )
                            // hash the password
                            if (newPlainTextPassword) {
                                hashPassword(newPlainTextPassword).then(
                                    (hashedPassword) => {
                                        // update the user with the new password
                                        userHelper.updateUser(row.email, {
                                            hashedPassword,
                                        })
                                    }
                                )
                            } else {
                                setToast({
                                    open: true,
                                    message: `No password entered`,
                                    severity: 'error',
                                })
                            }
                        }}
                    >
                        <TextSnippet />
                    </IconButton>
                </Tooltip>
            ),
        },
    ]

    const configColumns = [
        { headerName: 'Name', field: 'name', flex: 1 },
        {
            headerName: 'Config',
            field: 'config',
            flex: 1,
            renderCell: ({ row }) => (
                <Tooltip title="Edit config">
                    <IconButton
                        onClick={async () => {
                            // prompt the user for a new config
                            const newConfig = prompt(
                                `Enter a new config for ${row.name}`,
                                JSON.stringify(row.config)
                            )
                            // update the config
                            if (newConfig) {
                                try {
                                    const parsedConfig = JSON.parse(newConfig)
                                    await configHelper.updateConfig(row._id, {
                                        config: parsedConfig,
                                    })
                                } catch (error) {
                                    setToast({
                                        open: true,
                                        message: error.message,
                                        severity: 'error',
                                    })
                                }
                            } else {
                                setToast({
                                    open: true,
                                    message: `No config entered`,
                                    severity: 'error',
                                })
                            }
                        }}
                    >
                        <TextSnippet />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            headerName: 'Params',
            field: 'params',
            flex: 1,
            renderCell: ({ row }) => (
                <Tooltip title="Edit params">
                    <IconButton
                        onClick={async () => {
                            handleDialogOpen(row.params)
                            setCurrentId(row._id)
                        }}
                    >
                        <TextSnippet />
                    </IconButton>
                </Tooltip>
            ),
        },
    ]
    const handleDialogOpen = (params) => {
        setTextFieldValue(JSON.stringify(params, null, 2))
        setOpenDialog(true)
    }
    const handleDialogClose = () => {
        setOpenDialog(false)
    }

    const handleParamsChange = (event) => {
        setTextFieldValue(event.target.value)
    }

    const handleParamsSave = async () => {
        try {
            const parsedParams = JSON.parse(textFieldValue)
            await configHelper.updateConfig(currentId, parsedParams)
            setCurrentParams(parsedParams)
            setOpenDialog(false)
            fetchConfigs()
        } catch (error) {
            console.log(error)
            setToast({
                open: true,
                message: error.message,
                severity: 'error',
            })
        }
    }

    return (
        <div>
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Edit Params</DialogTitle>
                <DialogContent>
                    <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        value={textFieldValue}
                        onChange={handleParamsChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleParamsSave}>Save</Button>
                </DialogActions>
            </Dialog>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 12px',
                    height: 80,
                }}
            >
                <div style={{ display: 'flex', gap: 8 }}>
                    <span style={{ fontSize: 12 }}>
                        <Link to="/">
                            <Tooltip title="Go home">
                                <ArrowBack />
                            </Tooltip>
                        </Link>
                    </span>
                    <h3 style={{ padding: 0, margin: 0 }}>Superuser Tool</h3>
                </div>
            </div>
            <div
                style={{
                    marginBottom: 12,
                    marginLeft: 12,
                }}
            >
                {/* button for refreshing the admins */}
                <Button
                    variant="outlined"
                    onClick={() => {
                        setToast({
                            open: true,
                            message: `Refreshing admins...`,
                            severity: 'info',
                        })
                        fetchAdmins()
                    }}
                >
                    Refresh Admins
                </Button>
                {/* button for refreshing the configs */}
                <Button
                    variant="outlined"
                    onClick={() => {
                        setToast({
                            open: true,
                            message: `Refreshing configs...`,
                            severity: 'info',
                        })
                        fetchConfigs()
                    }}
                >
                    Refresh Configs
                </Button>
            </div>
            {admins && (
                <div style={{ width: '100%', height: 'calc(50vh - 80px)' }}>
                    <DataGridPro
                        columns={adminColumns}
                        rows={admins}
                        getRowId={(row) => row._id}
                        title="admins"
                        options={{
                            pagination: true,
                        }}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                    />
                </div>
            )}
            {/* button for creating a new config */}
            <Button
                variant="outlined"
                onClick={() => {
                    const name = prompt('Enter config name')
                    const value = prompt('Enter config value')
                    configHelper
                        .createNewConfig(name, value)
                        .then(() => {
                            setToast({
                                open: true,
                                message: `Creating new config...`,
                                severity: 'info',
                            })
                            fetchConfigs()
                        })
                        .catch((error) => {
                            setToast({
                                open: true,
                                message: `Error creating config: ${error.message}`,
                                severity: 'error',
                            })
                        })
                }}
            >
                Create Config
            </Button>

            {configs && (
                <div style={{ width: '100%', height: 'calc(50vh - 80px)' }}>
                    <DataGridPro
                        columns={configColumns}
                        rows={configs}
                        getRowId={(row) => row._id}
                        title="configs"
                        options={{
                            pagination: true,
                        }}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                    />
                </div>
            )}
            {/* button that copies the email addresses into the clipboard */}

            <Snackbar
                open={toast.open}
                autoHideDuration={4000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                    setToast(defaultToast)
                }}
                message={toast.message}
                severity={toast.severity}
            />
        </div>
    )
}

export default Superuser
