import baxios from '../baxios.js'

const entitlementHelper = {
    // Bugbug? this is assuming there is only one entitlement per user per app
    upsertEntitlementOnUserByEmail: async (entitlementData) => {
        console.log(
            'upsertEntitlementOnUserByEmail: called with params',
            entitlementData
        )
        let { email, appName } = entitlementData
        if (!email || !appName) {
            throw new Error(
                `upsertEntitlementOnUserByEmail: email ${email} and appName ${appName} are required`
            )
        }

        // call /entitlements
        let entitlement = await baxios.post(`entitlements`, {
            email,
            appName,
            ...entitlementData,
        })

        if (entitlement.status !== 200) {
            throw new Error(
                `upsertEntitlementOnUserByEmail: error creating entitlement: ${entitlement.data}`
            )
        }

        return entitlement.data
    },

    getAllEntitlements: async ({
        page = 0,
        limit = 1000,
        filter = {},
        populateFields = []
    } = {}) => {
        const serializedFilter = JSON.stringify(filter)
        const response = await baxios.get('/admin/users', {
            params: {
                page,
                limit,
                populateFields,
                filter: serializedFilter
            },
            timeout: 0
        })

        if (response.status !== 200) {
            throw new Error('Failed to fetch users')
        }

        const allEntitlements = response.data.users.reduce((acc, user) => {
            if (!user.entitlements) return acc
            
            const userEntitlements = user.entitlements.map(ent => ({
                ...ent,
                userEmail: user.email,
                status: ent.active ? 'active' : 'inactive',
                createdAt: ent.createdAt || user.createdAt
            }))
            
            return [...acc, ...userEntitlements]
        }, [])

        return {
            entitlements: allEntitlements,
            totalEntitlements: allEntitlements.length
        }
    },

    // search filters
    filterEntitlements: (entitlements, filterQuery) => {
        if (!filterQuery || Object.keys(filterQuery).length === 0) {
            return entitlements
        }

        return entitlements.filter(entitlement => {
            for (const [key, value] of Object.entries(filterQuery)) {
                if (typeof value === 'object' && value.$regex) {
                    const regex = new RegExp(value.$regex, value.$options || '')
                    if (!regex.test(entitlement[key])) return false
                } else {
                    if (entitlement[key] !== value) return false
                }
            }
            return true
        })
    },

    cancelEntitlement: async (email, appName) => {
        try {
            const response = await baxios.post('/entitlements/cancel', {
                email,
                appName
            });
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data || 'Failed to cancel entitlement');
        }
    },

    activateEntitlement: async (email, appName) => {
        try {
            const response = await baxios.post('/entitlements/activate', {
                email,
                appName
            });
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data || 'Failed to activate entitlement');
        }
    },

    deleteEntitlement: async (email, licenseKey) => {
        try {
            console.log('Deleting entitlement:', { email, licenseKey });
            const response = await baxios.delete('/admin/users/entitlement', {
                data: {
                    email,
                    licenseKey
                }
            });
            console.log('Delete response:', response);
            return response.data;
        } catch (error) {
            console.error('Delete error:', error);
            throw new Error(error.response?.data?.error?.message || error.response?.data || 'Failed to delete entitlement');
        }
    },

    transferEntitlement: async (email, licenseKey, transferEmail) => {
        try {
            console.log('Transferring entitlement:', { email, licenseKey, transferEmail });
            const response = await baxios.post('/entitlements/transfer', 
                { 
                    email, 
                    licenseKey, 
                    transferEmail 
                });
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data || 'Failed to transfer entitlement');
        }
    }
}

export default entitlementHelper
