import baxios from '../baxios.js'

const configHelper = {
    getAllConfigs: () => {
        return baxios.get(`/admin/configs`)
    },
    getConfigByName: (name) => {
        return baxios.get(`/admin/configs/${name}`)
    },
    updateConfig: (id, params) => {
        return baxios.post(`/admin/configs/${id}`, { params })
    },
    createNewConfig: (name, params) => {
        return baxios.post(`/admin/configs`, { name, params })
    },
}

export default configHelper
