import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth.js'
import { Link } from 'react-router-dom'

export const ProtectedLayout = () => {
    const { user, logout } = useAuth()

    if (!user) {
        logout()
        return <Navigate to="/login" />
    }

    return (
        <div>
            <nav>
                <div style={{ padding: '10px' }}>
                    <Link to={`/`}>Home</Link> |&nbsp;
                    <Link to={`/builds`}>Builds</Link> |&nbsp;
                    <Link to={`/user-tool`}>User Tool</Link> |&nbsp;
                    <Link to={`/support-tool`}>Support Tool</Link> |&nbsp;
                    <Link to={`/redemptions`}>Redemptions</Link> |&nbsp;
                    <Link to={`/manage-waitlist`}>Manage Waitlist</Link>
                    {user.superuser && (
                        <>
                            <span>
                                {' '}
                                |&nbsp; <Link to={`/products`}>Products</Link>
                            </span>
                            <span>
                                {' '}
                                |&nbsp; <Link to={`/superuser`}>Superuser</Link>
                            </span>
                        </>
                    )}
                    <span>
                        {' '}
                        |&nbsp; <Link to={`/logout`}>Log Out</Link>
                    </span>
                </div>
            </nav>
            <Outlet />
        </div>
    )
}
