import React from 'react'
import PropTypes from 'prop-types'

const Section = ({
    title,
    sectionKey,
    collapsedSections,
    toggleSection,
    children,
}) => (
    <div className={`product-download ${sectionKey}`}>
        <label
            className="section-label"
            onClick={() => toggleSection(sectionKey)}
        >
            {title}{' '}
            <span className="caret">
                {collapsedSections[sectionKey] ? '▼' : '▲'}
            </span>
        </label>
        {!collapsedSections[sectionKey] && <>{children}</>}
    </div>
)

Section.propTypes = {
    title: PropTypes.string.isRequired,
    sectionKey: PropTypes.string.isRequired,
    collapsedSections: PropTypes.object.isRequired,
    toggleSection: PropTypes.func.isRequired,
    children: PropTypes.node,
}

Section.defaultProps = {
    collapsedSections: {
        release: true,
        prerelease: true,
        samples: true,
        stripe: true,
        entitlementConfig: true,
    },
}

export default Section
