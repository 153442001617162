import React from 'react'

const ProductList = ({ products, handleEdit, handleDelete }) => {
    const sortedProducts = [...products].sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name)
        if (nameComparison !== 0) return nameComparison
        return (a.entitlementConfig?.subType || '').localeCompare(
            b.entitlementConfig?.subType || ''
        )
    })

    return (
        <ul className="product-list">
            {sortedProducts.map((product) => (
                <li key={product._id} className="product-item">
                    {product.name} ({product.appName}
                    {product.entitlementConfig?.subType
                        ? ` - ${product.entitlementConfig.subType}`
                        : ''}
                    )
                    <div className="item-actions">
                        <button
                            onClick={() => handleEdit(product)}
                            className="edit-btn"
                        >
                            Edit
                        </button>
                        <button
                            onClick={() => handleDelete(product._id)}
                            className="delete-btn"
                        >
                            Delete
                        </button>
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default ProductList
