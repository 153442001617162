import React, { useState } from 'react'
import stripeHelper from '../helpers/stripeHelper.js'
import { toast } from 'react-toastify'

const CreatePriceModal = ({ isOpen, onClose, onPriceCreated, productId }) => {
    const [amount, setAmount] = useState('')
    const [recurring, setRecurring] = useState(false)
    const [interval, setInterval] = useState('month')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setError(null)
        try {
            if (!amount || isNaN(parseFloat(amount))) {
                throw new Error('Please enter a valid amount')
            }
            const priceData = {
                amount: parseFloat(amount) * 100, // Convert to cents
                currency: 'usd', // Default to USD
                recurring: recurring,
                interval: recurring ? interval : null,
            }
            const newPrice = await stripeHelper.createStripePrice(
                productId,
                priceData
            )
            if (!newPrice || !newPrice.id) {
                throw new Error('Failed to create new price')
            }
            toast.success('New price created successfully')
            onPriceCreated(newPrice)
            onClose()
        } catch (error) {
            setError(error.message)
            toast.error(`Error creating price: ${error.message}`)
        } finally {
            setIsLoading(false)
        }
    }

    if (!isOpen) return null

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Create New Price</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label
                            htmlFor="amount"
                            style={{ display: 'block', whiteSpace: 'nowrap' }}
                        >
                            Amount (USD):
                        </label>
                        <input
                            type="number"
                            id="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            required
                            step="0.01"
                            disabled={isLoading}
                        />
                    </div>
                    <div className="form-group">
                        <label className="checkbox-label">
                            <input
                                type="checkbox"
                                checked={recurring}
                                onChange={(e) => setRecurring(e.target.checked)}
                                disabled={isLoading}
                            />
                            Recurring
                        </label>
                    </div>
                    {recurring && (
                        <div className="form-group">
                            <label htmlFor="interval">Interval:</label>
                            <select
                                id="interval"
                                value={interval}
                                onChange={(e) => setInterval(e.target.value)}
                                disabled={isLoading}
                            >
                                <option value="month">Monthly</option>
                                <option value="year">Yearly</option>
                            </select>
                        </div>
                    )}
                    {error && <div className="error-message">{error}</div>}
                    <div className="button-group">
                        <button
                            type="submit"
                            className="submit-btn"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Creating...' : 'Create Price'}
                        </button>
                        <button
                            type="button"
                            onClick={onClose}
                            className="cancel-btn"
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
            <style jsx="true">{`
                .modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;
                }
                .modal-content {
                    background-color: white;
                    padding: 30px;
                    border-radius: 8px;
                    width: 400px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                }
                h2 {
                    margin-top: 0;
                    margin-bottom: 20px;
                    color: #333;
                }
                .form-group {
                    margin-bottom: 20px;
                }
                label {
                    display: block;
                    margin-bottom: 5px;
                    color: #555;
                }
                input,
                select {
                    width: 100%;
                    padding: 8px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    font-size: 14px;
                }
                .checkbox-label {
                    display: flex;
                    align-items: center;
                }
                .checkbox-label input {
                    width: auto;
                    margin-right: 10px;
                }
                .button-group {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 30px;
                }
                button {
                    padding: 10px 20px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 14px;
                    transition: background-color 0.3s;
                }
                .submit-btn {
                    background-color: #4caf50;
                    color: white;
                }
                .submit-btn:hover:not(:disabled) {
                    background-color: #45a049;
                }
                .cancel-btn {
                    background-color: #f44336;
                    color: white;
                }
                .cancel-btn:hover:not(:disabled) {
                    background-color: #da190b;
                }
                button:disabled {
                    opacity: 0.6;
                    cursor: not-allowed;
                }
                .error-message {
                    color: #f44336;
                    margin-bottom: 10px;
                }
            `}</style>
        </div>
    )
}

export default CreatePriceModal
