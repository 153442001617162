import baxios from "../baxios.js"

export const dbSampleHelper = {
    saveSample: async (sample) => {
        try {
            return baxios.post(`/samples`, sample)
        } catch (error) {
            console.log('error in dbSampleHelper.saveSample:', error)
            throw error
        }
    },
    getAllSamples: async () => {
        try {
            return baxios.get(`/samples`)
        } catch (error) {
            console.log('error in dbSampleHelper.findAllSamples:', error)
        }
    },
    getSample: async (sampleId) => {
        try {
            return baxios.get(`/samples/${sampleId}`)
        } catch (error) {
            console.log('error in dbSampleHelper.getSample:', error)
        }
    },
    getSamplesWithTag: async (tag) => {
        try {
            return baxios.get(`/samples/tag/${tag}`)
        } catch (error) {
            console.log('error in dbSampleHelper.findSamplesWithTag:', error)
        }
    },
    addTagToSample: async (sampleId, tag) => {
        try {
            return baxios.patch(`/samples/${sampleId}/tag`, {
                tag,
            })
        } catch (error) {
            console.log('error in dbSampleHelper.addTagToSample:', error)
        }
    },
    removeTagFromSample: async (sampleId, tag) => {
        try {
            return baxios.patch(`/samples/${sampleId}/tag`, {
                tag,
                remove: true,
            })
        } catch (error) {
            console.log('error in dbSampleHelper.removeTagFromSample:', error)
        }
    },
    replaceTagsOnSample: async (sampleId, tags) => {
        try {
            return baxios.patch(`/samples/${sampleId}`, {
                tags,
            })
        } catch (error) {
            console.log('error in dbSampleHelper.replaceTagsOnSample:', error)
        }
    },
    getAllTagsFromSamples: async () => {
        return dbSampleHelper.getAllSamples().then((samples) => {
            let allTagsWithCount = []

            samples.data.forEach((sample) => {
                sample.tags.forEach((tag) => {
                    if (allTagsWithCount.find((t) => t.tag === tag)) {
                        allTagsWithCount.find((t) => t.tag === tag).count += 1
                    } else {
                        allTagsWithCount.push({
                            tag,
                            count: 1,
                        })
                    }
                })
            })

            return allTagsWithCount.sort((a, b) => a.tag.localeCompare(b.tag))
        })
    },
    deleteSampleById: async (sampleId) => {
        try {
            return baxios.delete(`/samples/${sampleId}`)
        } catch (error) {
            console.log('error in dbSampleHelper.deleteSampleById:', error)
        }
    },
}
