import baxios from '../baxios.js'

const userHelper = {
    getUsers: async ({
        page = 0,
        limit = 1000,
        filter,
        populateFields = [],
    }) => {
        console.log('getUsers params', {
            page,
            limit,
            filter,
            populateFields,
        })
        // Serialize the filter object to ensure boolean values are preserved
        const serializedFilter = JSON.stringify(filter)
        const response = await baxios.get(
            `/admin/users`,
            {
                params: {
                    page,
                    limit,
                    populateFields,
                    filter: serializedFilter,
                },
            },
            {
                timeout: 0, // Setting no timeout
            }
        )
        return response.data
    },

    // get a single user by email
    getSingleUser: async (filter) => {
        console.log('getSingleUser filter', filter)
        const response = await baxios.get(`/admin/users`, {
            params: {
                filter,
            },
        })
        console.log('getSingleUser response', response)
        return response.data.users ? response.data.users[0] : null
    },
    registerUser: async (data) => {
        try {
            let res = await baxios.post(`/users/register`, data)

            if (res.status !== 200) {
                return { error: res?.error, status: res.status }
            }

            return {
                message: `successfully registered user ${data.email}`,
                user: res.data,
                status: res.status,
            }
        } catch (error) {
            console.error(error)
            return { error }
        }
    },

    // update a user by email by calling /admin/users/update
    updateUser: async (email, data = {}) => {
        console.log(`updating user ${email} with data`, data)
        try {
            // make sure email is defined and a string
            if (!email || typeof email !== 'string' || email.length === 0) {
                throw new Error(
                    'email is required and must be a string and not empty'
                )
            }
            if (typeof data !== 'object' || Object.keys(data).length === 0) {
                throw new Error(
                    'data is required and must be an object that is not empty'
                )
            }
            let res = await baxios.post(`/admin/users/update`, {
                email,
                updateFields: data,
            })

            if (res.status !== 200) {
                return { error: res?.error, status: res.status }
            }

            return {
                message: `successfully updated user ${data.email}`,
                user: res.data,
                status: res.status,
            }
        } catch (error) {
            console.error(error)
            return { error }
        }
    },
}

export default userHelper
