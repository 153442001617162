import { useEffect } from 'react'
import { useAuth } from '../hooks/useAuth.js'
import baxios from '../baxios.js'

const Logout = () => {
    const { logout } = useAuth()

    useEffect(() => {
        // we actually do not care about the promise after we send it off
        baxios.post('/admin/auth/logout')
        logout()
    }, [])

    return (
        <div>
            <h1>Sayonara.</h1>
        </div>
    )
}

export default Logout
