import baxios from '../baxios.js'

const mailchimpHelper = {
    sendFullfillmentEmail: async (opts) => {
        let {
            email,
            licenseKey,
            templateName = 'purchase_fulfillment',
            appName,
            subType,
        } = opts

        return baxios.post(`/mailchimp/fulfillment/send`, {
            email,
            licenseKey,
            templateName,
            appName,
            subType,
        })
    },
    getAllEmailTemplates: async () => {
        return baxios.get(`/mailchimp/templates`)
    },
}

export default mailchimpHelper
