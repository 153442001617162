import { useState } from 'react'
import baxios from '../baxios.js'
import LoginForm from '../components/LoginForm.js'
import { useAuth } from '../hooks/useAuth.js'

const Login = () => {
    const { login } = useAuth()
    const [loading, setLoading] = useState(false)

    const handleLoginSubmit = (data) => {
        setLoading(true)

        baxios
            .post('/admin/auth/login', {
                email: data.email,
                password: data.password,
            })
            .then((e) => {
                const user = e.data
                console.log(user)
                login(user)
            })
            .catch((e) => {
                console.error(e)
                if (e.name === 'AxiosError') {
                    alert(e.message)
                } else {
                    alert(e.response.statusText)
                }
            })
            .finally((_) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h1>You gotta log in dawg.</h1>

            <div>
                <LoginForm
                    disableSubmit={loading}
                    onSubmit={handleLoginSubmit}
                ></LoginForm>
            </div>
        </div>
    )
}

export default Login
