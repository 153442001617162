import React, { useState } from 'react'
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material'

const EditRedemptionForm = ({ redemption, onSubmit }) => {
    const [name, setName] = useState(redemption.name)
    const [notes, setNotes] = useState(redemption.notes)
    const [url, setUrl] = useState(redemption.url)
    const [contactEmail, setContactEmail] = useState(redemption.contactEmail)

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit({
            _id: redemption._id,
            name,
            notes,
            url,
            contactEmail,
        })
    }

    return (
        // make the form scrollable
        <form onSubmit={handleSubmit} style={{ overflowY: 'scroll' }}>
            <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
            />
            <TextField
                label="Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                fullWidth
            />
            <TextField
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                required
                fullWidth
            />
            <TextField
                label="Contact Email"
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                required
                fullWidth
            />
            <Button type="submit" variant="contained">
                Save
            </Button>
        </form>
    )
}

export default EditRedemptionForm
