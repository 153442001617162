// a page that shows retention data for the service
import { ArrowBack } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import buildHelper from '../helpers/buildHelper.js'
import configHelper from '../helpers/configHelper.js'

const Builds = () => {
    // load the builds when the page loads
    const [builds, setBuilds] = useState([])
    const [currentActiveBuild, setCurrentActiveBuild] = useState(null)
    const [s3BuildZipFiles, setS3BuildZipFiles] = useState(null)
    const [emailText, setEmailText] = useState('')

    const {
        register,
        setError,
        handleSubmit,
        watch,
        reset,
        formState,
        formState: { errors, isSubmitSuccessful },
    } = useForm()

    const styles = {
        container: {
            width: '80%',
            margin: '0 auto',
        },
        input: {
            width: '100%',
        },
        buildInList: {
            // set the margin left to 20px
            marginLeft: '20px',
            // display it on the same line
            display: 'inline-block',
        },
        fileUploader: {
            marginBottom: '20px',
        },
    }

    console.log('releaseNotes: ', watch('releaseNotes')) // watch input value by passing the name of it
    console.log('emailBlurbHtml: ', watch('emailBlurbHtml')) // watch input value by passing the name of it
    console.log('buildZipFile: ', watch('buildZipFile')) // watch input value by passing the name of it
    console.log('errors: ', errors) // watch input value by passing the name of it

    useEffect(() => {
        if (!currentActiveBuild) {
            setEmailText('')
            return
        }
        const emailText = constructActiveBuildEmailText()
        setEmailText(emailText)
    }, [currentActiveBuild])

    useEffect(() => {
        try {
            fetchBuilds()
        } catch (error) {
            setError(error)
        }
    }, [])

    // useEffect(() => {
    //     if (formState.isSubmitSuccessful) {
    //         reset({ something: '' })
    //     }
    // }, [formState, reset])

    const fetchBuilds = async () => {
        let builds
        let currentActiveBuild
        let s3Builds

        try {
            builds = await buildHelper.getAllBuilds()
        } catch (error) {
            error = JSON.stringify(error)
            setError(error)
            return
        }
        try {
            currentActiveBuild = await buildHelper.getActiveBuild()
        } catch (error) {
            // seterror as a string
            error = JSON.stringify(error)
            console.log(error)
            toast.error(error)
            return
        }
        try {
            s3Builds = await buildHelper.getS3Builds()
        } catch (error) {
            error = JSON.stringify(error)
            console.log(error)
            toast.error(error)
            return
        }

        setCurrentActiveBuild(currentActiveBuild)
        setBuilds(builds)
        setS3BuildZipFiles(s3Builds)
    }

    const handleStartNewBuild = () => {
        // clear the form
        reset({})

        // clear all text from the form
        setEmailText('')
    }
    const handleCreateBuild = async (data) => {
        let response
        let s3Response

        // try to upload the build zip file to s3
        if (!data.downloadUrl) {
            try {
                if (!data.buildZipFile) {
                    throw new Error('no buildZipFile provided')
                }
                const zipFileName = data.buildZipFile[0].name
                s3Response = await buildHelper.uploadZipFile({
                    zipFileName,
                    zipFile: data.buildZipFile[0],
                })
                console.log('s3Response: ', s3Response)
                data.downloadUrl = s3Response.data.downloadUrl
            } catch (error) {
                console.log('error: ', error)
                setError(error)
                return
            }
        }

        if (data._id) {
            response = await buildHelper.updateBuild(data._id, data)
        } else {
            response = await buildHelper.createBuild(data)
        }

        if (response.status === 200) {
            await fetchBuilds()

            let isUpdate = !!data._id

            // show success message
            let message = isUpdate
                ? 'Build updated successfully'
                : 'Build created successfully'

            toast.success(message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            // clear the form
            if (!isUpdate) {
                reset()
            }
        } else {
            setError(response.data.message)
        }
    }

    const onSubmit = async (data) => {
        console.log(data)
        await handleCreateBuild(data)
    }

    const handleBuildSelect = (build) => {
        console.log(build)
        // set the form values without changing the default values
        reset(build)
    }

    const handleBuildSetActive = async (build) => {
        console.log(build)
        try {
            await buildHelper.setActiveBuild(build._id)
            await fetchBuilds()
        } catch (error) {
            setError(error)
        }
    }

    const handleBuildDelete = async (build) => {
        console.log(build)

        // dont allow delete if the build is active
        if (build._id === currentActiveBuild._id) {
            // toast error
            toast.error('Cannot delete active build', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return
        }

        // pop an "are you sure" dialog, and if they click yes, delete the build
        if (window.confirm('Are you sure you want to delete this build?')) {
            // delete the build
            try {
                await buildHelper.deleteBuild(build._id)
                fetchBuilds()
            } catch (error) {
                setError(error)
            }
        }
    }

    const constructActiveBuildEmailText = () => {
        let emailText = `${currentActiveBuild.emailBlurbHtml}\n\nRelease Notes\n${currentActiveBuild.releaseNotes}\n\n<a href="${currentActiveBuild.downloadUrl}">download the build</a>`
        return emailText
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 12px',
                    height: 80,
                }}
            >
                <div style={{ display: 'flex', gap: 8 }}>
                    <span style={{ fontSize: 12 }}>
                        <Link to="/">
                            <Tooltip title="Go home">
                                <ArrowBack />
                            </Tooltip>
                        </Link>
                    </span>
                    <h3 style={{ padding: 0, margin: 0 }}>Builds</h3>
                </div>
            </div>

            {/* CRUD for builds */}
            <h2>All builds</h2>
            {/* a collapsable list */}
            <ul>
                {builds &&
                    builds.length > 0 &&
                    builds.map((build) => {
                        // each build li should have buttons for select and delete
                        return (
                            <li key={build._id}>
                                <button
                                    onClick={() => handleBuildSelect(build)}
                                >
                                    select
                                </button>
                                <button
                                    onClick={() => handleBuildDelete(build)}
                                >
                                    delete
                                </button>
                                <button
                                    onClick={() => handleBuildSetActive(build)}
                                >
                                    set active
                                </button>
                                {/* mark as active if this is the current active build */}
                                <p style={styles.buildInList}>
                                    {build.downloadUrl}
                                </p>
                                {currentActiveBuild &&
                                    currentActiveBuild._id === build._id && (
                                        <p style={styles.buildInList}>
                                            (active build)
                                        </p>
                                    )}
                            </li>
                        )
                    })}
            </ul>
            {/* button to clear the form and start a new build */}
            <button onClick={handleStartNewBuild}>start new build</button>
            {/* form for adding a new build, all fields required */}
            <h2>Build Details</h2>
            {/* align the fields one vertically */}
            <form
                style={{ display: 'flex', flexDirection: 'column' }}
                onSubmit={handleSubmit(onSubmit)}
                encType="multipart/form-data"
            >
                {/* allow users to select a zip file from disk */}
                {/* dont show it if the build already has a downloadUrl */}
                {!watch('downloadUrl') && (
                    <div style={styles.fileUploader}>
                        <label>Upload the build</label>
                        <input
                            type="file"
                            id="buildZipFile"
                            name="uploadedFile"
                            style={styles.fileUploader}
                            {...register('buildZipFile', {
                                required: 'Build zip file is required',
                            })}
                        />

                        {errors.buildZipFile && (
                            <p style={{ color: 'red' }}>
                                {errors.buildZipFile.message}
                            </p>
                        )}
                    </div>
                )}

                <label htmlFor="releaseNotes">Release Notes</label>
                <textarea
                    id="releaseNotes"
                    style={styles.input}
                    {...register('releaseNotes', {
                        required: 'Release Notes are required',
                    })}
                    // grow the textarea as the user types
                    rows={
                        watch('releaseNotes') &&
                        watch('releaseNotes').split('\n').length
                    }
                />
                <p>{errors.releaseNotes?.message}</p>

                <label htmlFor="emailBlurbHtml">Email Blurb</label>
                <textarea
                    id="emailBlurbHtml"
                    style={styles.input}
                    {...register('emailBlurbHtml', {
                        required: 'Email Blurb is required',
                    })}
                    // grow the textarea as the user types
                    rows={
                        watch('emailBlurbHtml') &&
                        watch('emailBlurbHtml').split('\n').length
                    }
                />
                <p>{errors.emailBlurbHtml?.message}</p>

                {/* show any other errors */}
                <p>{errors.message}</p>
                <input type="submit" />
            </form>

            <h2>Email Template</h2>
            {/* show a non-editable text area of an email we can send everyone */}
            <textarea
                style={styles.input}
                value={emailText}
                rows={emailText.split('\n').length}
            />

            <h2>Files on S3</h2>
            <ul>
                {/* print out the key value pairs of the map as a list */}
                {s3BuildZipFiles &&
                    Object.entries(s3BuildZipFiles).map(([key, value]) => (
                        <li
                            key={key}
                            style={{ display: 'flex', flexDirection: 'row' }}
                        >
                            <p>{key}</p>
                            {/* value as a link */}
                            <a href={value} target="_blank">
                                test link
                            </a>
                        </li>
                    ))}
                {/* or */}
                {(!s3BuildZipFiles || s3BuildZipFiles.length === 0) && (
                    <p>No builds found on S3</p>
                )}
            </ul>
            <ToastContainer />
        </div>
    )
}

export default Builds
