const USE_BACKUP_DOMAIN = false

export const DOMAIN = USE_BACKUP_DOMAIN ? 'audialab.co' : 'audialab.com'
const backend_server_lookup = {
    development: `https://dev.${DOMAIN}`,
    production: `https://api.${DOMAIN}`,
    local: 'http://localhost:4000',
}

const inference_server_lookup = {
    development: `https://inf2.${DOMAIN}`,
    production: `https://inf2.${DOMAIN}`,
    local: `https://inf2.${DOMAIN}`,
}

const deepsampler_server_lookup = {
    development: `https://deepsampler.${DOMAIN}`,
    production: `https://deepsampler.${DOMAIN}`,
    local: `http://localhost:3003`,
}

var react_environment = 'local'

if (typeof window !== 'undefined') {
    if (window.location.href.includes(`/dev.${DOMAIN}`)) {
        // console.log('Running on development server.')
        // console.log('Setting react_environment to "development"')

        react_environment = 'development'
    } else if (window.location.href.includes('localhost')) {
        react_environment = 'local'
    } else if (window.location.href.includes(`${DOMAIN}`)) {
        react_environment = 'production'
    }
} else {
    // console.log(`Running on unknown server: "${window.location.href}".`)
}

// console.log('react_environment:', react_environment)
export const BACKEND_SERVER = backend_server_lookup[react_environment]
export const INFERENCE_SERVER = inference_server_lookup[react_environment]
export const DEEPSAMPLER_SERVER = deepsampler_server_lookup[react_environment]

// console.log('Using backend server:', BACKEND_SERVER)
// console.log('Using inference server:', INFERENCE_SERVER)
