import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    FormControl,
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    Snackbar
} from '@mui/material';
import entitlementHelper from '../helpers/entitlementHelper.js';
import userHelper from '../helpers/userHelper.js';

const AddNewUserDialog = ({ open, onClose, prefilledEmail, setToast }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState(prefilledEmail || '');

    useEffect(() => {
        setEmail(prefilledEmail || '');
    }, [prefilledEmail]);

    function resetValues() {
        setFirstName('');
        setLastName('');
        setEmail('');
    }

    async function handleSubmit() {
        let response = await userHelper.registerUser({
            firstName,
            lastName,
            email,
        });

        if (response.status !== 200) {
            setToast({
                open: true,
                message: response.error,
                severity: 'error',
            });
            onClose();
        } else {
            setToast({
                open: true,
                message: `Added user with the email of ${email}`,
                severity: 'success',
            });
            resetValues();
            onClose(true, response.user);
        }
    }

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Add a user</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    autoFocus
                    id="firstName"
                    label="First name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="lastName"
                    label="Last name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="standard"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const UserEntitlements = ({ user, onEntitlementUpdate }) => {
    const [confirmationDialog, setConfirmationDialog] = useState({
        open: false,
        action: null,
        entitlement: null
    });
    const [transferEmail, setTransferEmail] = useState('');
    const [targetUserExists, setTargetUserExists] = useState(null);
    const [isCheckingUser, setIsCheckingUser] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [toast, setToast] = useState({ open: false, message: '', severity: 'info' });

    const handleActionClick = (action, entitlement) => {
        setConfirmationDialog({
            open: true,
            action,
            entitlement
        });
        if (action === 'transfer') {
            setTransferEmail('');
            setTargetUserExists(null);
        }
    };

    const handleEmailCheck = async (email) => {
        if (!email) return;
        
        setIsCheckingUser(true);
        try {
            const targetUser = await userHelper.getSingleUser({ email });
            setTargetUserExists(!!targetUser);
        } catch (error) {
            console.error('Error checking user:', error);
            setTargetUserExists(false);
        } finally {
            setIsCheckingUser(false);
        }
    };

    const handleTransferEmailChange = (e) => {
        const email = e.target.value;
        setTransferEmail(email);
        if (email) {
            handleEmailCheck(email);
        } else {
            setTargetUserExists(null);
        }
    };

    const handleConfirmAction = async () => {
        const { action, entitlement } = confirmationDialog;
        
        try {
            if (action === 'transfer') {
                if (!targetUserExists) {
                    setShowAddUserModal(true);
                    return;
                }
                await entitlementHelper.transferEntitlement(user.email, entitlement.licenseKey, transferEmail);
            } else if (action === 'cancel') {
                await entitlementHelper.cancelEntitlement(user.email, entitlement.appName);
            } else if (action === 'uncancel') {
                await entitlementHelper.activateEntitlement(user.email, entitlement.appName);
            } else if (action === 'delete') {
                await entitlementHelper.deleteEntitlement(user.email, entitlement.licenseKey);
            }
            
            if (onEntitlementUpdate) {
                await onEntitlementUpdate();
            }
        } catch (error) {
            console.error('Error in entitlement action:', error);
        }
        
        setConfirmationDialog({ open: false, action: null, entitlement: null });
        setTransferEmail('');
        setTargetUserExists(null);
    };

    const handleNewUserCreated = async (success, newUser) => {
        setShowAddUserModal(false);
        
        if (success) {
            try {
                // verify new user exists before tranferring entitlement
                const verifiedUser = await userHelper.getSingleUser({ email: transferEmail });
                if (!verifiedUser) {
                    throw new Error('Failed to verify new user exists');
                }
                
                console.log('transferring entitlement:', {
                    fromEmail: user.email,
                    licenseKey: confirmationDialog.entitlement.licenseKey,
                    toEmail: verifiedUser.email
                });
                
                const transferResult = await entitlementHelper.transferEntitlement(
                    user.email, 
                    confirmationDialog.entitlement.licenseKey, 
                    verifiedUser.email
                );
                
                if (onEntitlementUpdate) {
                    await onEntitlementUpdate();
                }

                setToast({
                    open: true,
                    message: 'Entitlement transferred successfully',
                    severity: 'success'
                });
            } catch (error) {
                console.error('Error transferring entitlement to new user:', error);
                setToast({
                    open: true,
                    message: `Error transferring entitlement: ${error.message}`,
                    severity: 'error'
                });
            }
        } else {
            console.log('User creation failed/cancelled');
        }
        setConfirmationDialog({ open: false, action: null, entitlement: null });
    };

    const getConfirmationMessage = () => {
        const { action, entitlement } = confirmationDialog;
        if (!entitlement) return '';

        switch (action) {
            case 'cancel':
                return `Are you sure you want to cancel the ${entitlement.appName} entitlement?`;
            case 'uncancel':
                return `Are you sure you want to reactivate the ${entitlement.appName} entitlement?`;
            case 'delete':
                return `Are you sure you want to delete the ${entitlement.appName} entitlement? This action cannot be undone.`;
            case 'transfer':
                return `Specify the user to transfer the ${entitlement.appName} entitlement to:`;
            default:
                return '';
        }
    };

    const getConfirmButtonText = () => {
        const { action } = confirmationDialog;
        if (action === 'transfer') {
            if (isCheckingUser) {
                return <CircularProgress size={24} color="inherit" />;
            }
            return targetUserExists ? 'Transfer to Existing User' : 'Transfer to New User';
        }
        return 'Confirm';
    };

    return (
        <Box sx={{ mt: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
                Entitlements
            </Typography>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>App</TableCell>
                            <TableCell>License Key</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {user.entitlements?.map((entitlement) => (
                            <TableRow key={entitlement.licenseKey}>
                                <TableCell>{entitlement.appName}</TableCell>
                                <TableCell>{entitlement.licenseKey}</TableCell>
                                <TableCell>{entitlement.active ? 'Active' : 'Inactive'}</TableCell>
                                <TableCell>
                                    {new Date(entitlement.createdAt).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    <FormControl size="small">
                                        <Select
                                            value=""
                                            onChange={(e) => handleActionClick(e.target.value, entitlement)}
                                            displayEmpty
                                        >
                                            <MenuItem value="" disabled>
                                                Select Action
                                            </MenuItem>
                                            <MenuItem value={entitlement.active ? "cancel" : "uncancel"}>
                                                {entitlement.active ? "Cancel" : "Un-Cancel"}
                                            </MenuItem>
                                            <MenuItem value="delete">Delete</MenuItem>
                                            <MenuItem value="transfer">Transfer</MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={confirmationDialog.open}
                onClose={() => {
                    setConfirmationDialog({ open: false, action: null, entitlement: null });
                    setTransferEmail('');
                    setTargetUserExists(null);
                }}
            >
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <Typography>{getConfirmationMessage()}</Typography>
                    {confirmationDialog.action === 'transfer' && (
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Email Address"
                            type="email"
                            fullWidth
                            value={transferEmail}
                            onChange={handleTransferEmailChange}
                            sx={{ mt: 2 }}
                        />
                    )}
                    {confirmationDialog.entitlement && (
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            License Key: {confirmationDialog.entitlement.licenseKey}<br />
                            Current User: {user.email}<br />
                            App: {confirmationDialog.entitlement.appName}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => {
                            setConfirmationDialog({ open: false, action: null, entitlement: null });
                            setTransferEmail('');
                            setTargetUserExists(null);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleConfirmAction} 
                        variant="contained" 
                        color="primary"
                        disabled={confirmationDialog.action === 'transfer' && !transferEmail}
                        sx={{ minWidth: 250 }}
                    >
                        {getConfirmButtonText()}
                    </Button>
                </DialogActions>
            </Dialog>

            <AddNewUserDialog
                open={showAddUserModal}
                onClose={handleNewUserCreated}
                prefilledEmail={transferEmail}
                setToast={setToast}
            />
            
            <Snackbar
                open={toast.open}
                autoHideDuration={4000}
                onClose={() => setToast({ ...toast, open: false })}
                message={toast.message}
                severity={toast.severity}
            />
        </Box>
    );
};

export default UserEntitlements; 