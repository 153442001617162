import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals.js'
import {
    BrowserRouter,
    Routes,
    Route,
    createRoutesFromElements,
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom'

import UserTool from './pages/user-tool.js'
import Builds from './pages/builds.js'
import Redemptions from './pages/redemptions.js'
import TagSounds from './pages/tag-sounds.js'
import ReviewTags from './pages/review-tags.js'
import ManageWaitlist from './pages/manage-waitlist.js'
import { ProtectedLayout } from './layouts/ProtectedLayout.js'
import { AuthLayout } from './layouts/AuthLayout.js'
import Login from './pages/login.js'
import Logout from './pages/logout.js'
import Home from './pages/home.js'
import Superuser from './pages/superuser.js'
import Products from './pages/products.js'
import SupportTool from './pages/support-tool.js'

import { LicenseInfo } from '@mui/x-license-pro'

// this gets rid of a watermark
LicenseInfo.setLicenseKey(
    '3e76c06089d3089542dc7e24d1bdfabfTz03Njk5OCxFPTE3MjkzNjEzMTAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<AuthLayout />}>
            <Route path="login" element={<Login />} />

            <Route element={<ProtectedLayout />}>
                <Route path="logout" element={<Logout />} />
                <Route path="/" element={<Home />} />
                <Route path="user-tool" element={<UserTool />} />
                <Route path="products" element={<Products />} />
                <Route path="support-tool" element={<SupportTool />} />
                <Route path="builds" element={<Builds />} />
                <Route path="redemptions" element={<Redemptions />} />
                {/* <Route path="tag-sounds" element={<TagSounds />} />
                <Route path="review-tags" element={<ReviewTags />} /> */}
                <Route path="manage-waitlist" element={<ManageWaitlist />} />
                <Route path="superuser" element={<Superuser />} />
            </Route>
        </Route>
    )
)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    // <React.StrictMode>
    <RouterProvider router={router} />
    // </React.StrictMode>
)

reportWebVitals()
