// Redemptions enable campaigns where we give a reseller a set of keys they can give to their customers.
// We need this bc a lot of resellers are archaic and can't call into our APIs via webhooks, so they want
// to get a set of keys upfront. This is not how we do things and it presents a few problems that the redemption
// system is intended to solve.
//
// When a customer buys our products from a reseller, they will get sent a license keys associated with a
// redemption. They can then enter it into our redemption page and trigger a fulfillment email without
// having to pay us directly. Essentially, those license keys will allow "free" downloads of our software (we
// get paid from the reseller).

import React, { useState, useEffect } from 'react'
import redemptionHelper from '../helpers/redemptionHelper.js'

import { Modal } from '@mui/material'
import EditRedemptionForm from '../components/EditRedemptionForm.js'
import CreateRedemptionForm from '../components/CreateRedemptionForm.js'

const Redemptions = () => {
    const [redemptions, setRedemptions] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [selectedRedemption, setSelectedRedemption] = useState(null)

    useEffect(() => {
        redemptionHelper.getRedemptions().then((response) => {
            console.log('response.data', response.data)
            setRedemptions(response.data)
        })
    }, [])

    const openModal = (redemption) => {
        setModalIsOpen(true)
        setSelectedRedemption(redemption)
    }

    const closeModal = () => {
        setModalIsOpen(false)
        setSelectedRedemption(null)
    }

    const handleDeleteRedemption = (redemptionId) => {
        if (
            !window.confirm('Are you sure you want to delete this redemption?')
        ) {
            return
        }

        redemptionHelper.deleteRedemption(redemptionId).then(() => {
            setRedemptions(
                redemptions.filter(
                    (redemption) => redemption._id !== redemptionId
                )
            )
        })
    }
    const downloadBlob = (blob, filename) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const handleDownloadRedeemedKeys = (redemptionId) => {
        let selectedRedemption = redemptions.find(
            (redemption) => redemption._id === redemptionId
        )
        if (selectedRedemption.licenseKeys.length === 0) {
            alert('There are no keys for this redemption')
            return
        }

        let redeemedLicenseKeysObjects = selectedRedemption.licenseKeys.filter(
            (licenseKey) => licenseKey.redeemed
        )

        // collect the data for redeemed license keys
        // and format it as a CSV
        // licenseKey, email, redeemedAt
        let licenseKeys = redeemedLicenseKeysObjects.map(
            (redeemedLicenseKeyObject) => {
                return `${redeemedLicenseKeyObject.licenseKey}, ${redeemedLicenseKeyObject.redeemedByEmail}, ${redeemedLicenseKeyObject.redeemedAt}`
            }
        )

        const blob = new Blob([licenseKeys.join('\r')], {
            type: 'text/csv',
        })

        let redemptionNameForFilename = selectedRedemption.name.replace(
            /[^a-zA-Z0-9]/g,
            '-'
        )
        downloadBlob(blob, `${redemptionNameForFilename}-redeemed-keys.csv`)
    }

    const handleDownloadUnredeemedKeys = (redemptionId) => {
        let selectedRedemption = redemptions.find(
            (redemption) => redemption._id === redemptionId
        )
        if (selectedRedemption.licenseKeys.length === 0) {
            alert('There are no unredeemed keys for this redemption')
            return
        }

        let unredeemedLicenseKeysObjects =
            selectedRedemption.licenseKeys.filter(
                (licenseKey) => !licenseKey.redeemed
            )

        let licenseKeys = unredeemedLicenseKeysObjects.map(
            (unredeemedLicenseKeyObject) =>
                unredeemedLicenseKeyObject.licenseKey
        )

        const blob = new Blob([licenseKeys.join('\r')], {
            type: 'text/csv',
        })

        let redemptionNameForFilename = selectedRedemption.name.replace(
            /[^a-zA-Z0-9]/g,
            '-'
        )

        downloadBlob(blob, `${redemptionNameForFilename}-unredeemed-keys.csv`)
    }

    const handleToggleActive = (redemptionId) => {
        console.log(`redemptionId: ${redemptionId}`)
        const redemption = redemptions.find(
            (redemption) => redemption._id === redemptionId
        )

        redemptionHelper
            .updateRedemption(redemptionId, {
                ...redemption,
                active: !redemption.active,
            })
            .then(() => {
                console.log(`redemption updated ${JSON.stringify(redemption)}`)

                setRedemptions(
                    redemptions.map((redemption) => {
                        if (redemption._id === redemptionId) {
                            return {
                                ...redemption,
                                active: !redemption.active,
                            }
                        }
                        return redemption
                    })
                )
            })
    }

    const getNumberRedeemedString = (redemption) => {
        const numberRedeemed = redemption.licenseKeys.filter(
            (licenseKey) => licenseKey.redeemed
        ).length
        return `${numberRedeemed} / ${redemption.licenseKeys.length}`
    }

    return (
        <div>
            <h2>Redemptions</h2>
            {/* an explanation of Redemptions */}
            <p>
                Redemptions enable campaigns where we give a reseller a set of
                keys they can give to their customers. We need this bc a lot of
                resellers are archaic and can't call into our APIs via webhooks,
                so they want to get a set of keys upfront. This is not how we do
                things and it presents a few problems that the redemption system
                is intended to solve.
                <br />
                <br />
                When a customer buys our products from a reseller, they will get
                a license key associated with a redemption. They can then enter
                it into our /redeem page and trigger a fulfillment email without
                having to pay us directly. Essentially, those license keys will
                allow "free" downloads of our software (we get paid from the
                reseller).
                <br />
                <br />
            </p>
            <button onClick={() => openModal(null)}>Create Redemption</button>
            <br />
            <br />
            <Modal
                open={modalIsOpen}
                onClose={closeModal}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        width: '90%',
                        height: '50%',
                        backgroundColor: 'white',
                        padding: '20px',
                    }}
                >
                    {selectedRedemption ? (
                        <EditRedemptionForm
                            redemption={selectedRedemption}
                            onSubmit={async (newRedemptionFields) => {
                                await redemptionHelper
                                    .updateRedemption(
                                        selectedRedemption._id,
                                        newRedemptionFields
                                    )
                                    .then((updatedRedemption) => {
                                        console.log(
                                            `inside then: updatedRedemption: ${JSON.stringify(
                                                updatedRedemption
                                            )}`
                                        )
                                        setRedemptions(
                                            redemptions.map((redemption) => {
                                                if (
                                                    redemption._id ===
                                                    updatedRedemption._id
                                                ) {
                                                    return updatedRedemption
                                                }
                                                return redemption
                                            })
                                        )
                                        closeModal()
                                    })
                                    .catch((error) => {
                                        console.log(
                                            `error updating redemption. "response": ${JSON.stringify(
                                                error
                                            )}`
                                        )

                                        let errorMessage = error.message

                                        alert(`GOT ERROR: ${errorMessage}`)
                                        console.log(errorMessage)
                                    })
                            }}
                        />
                    ) : (
                        <CreateRedemptionForm
                            onSubmit={(newRedemption) => {
                                console.log(
                                    `newRedemption: ${JSON.stringify(
                                        newRedemption
                                    )}`
                                )
                                redemptionHelper
                                    .createRedemption(newRedemption)
                                    .then((response) => {
                                        setRedemptions([
                                            ...redemptions,
                                            response.data,
                                        ])
                                        closeModal()
                                    })
                            }}
                        />
                    )}
                </div>
            </Modal>
            <table
                style={{
                    // border: '1px solid black',
                    borderCollapse: 'collapse',
                    // space out the table cells
                }}
            >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Notes</th>
                        <th>URL</th>
                        <th>Contact Email</th>
                        <th>Status</th>
                        <th>Number Redeemed</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {redemptions.map((redemption) => (
                        <tr
                            key={redemption._id}
                            style={{ border: '1px solid' }}
                        >
                            <td>{redemption.name}</td>
                            <td>{redemption.notes}</td>
                            <td>
                                <a
                                    href={redemption.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {redemption.url}
                                </a>
                            </td>
                            <td>{redemption.contactEmail}</td>
                            <td>{redemption.active ? 'Active' : 'Inactive'}</td>
                            <td>{getNumberRedeemedString(redemption)}</td>
                            <td>
                                <button
                                    onClick={() =>
                                        handleDownloadUnredeemedKeys(
                                            redemption._id
                                        )
                                    }
                                >
                                    Download Unredeemed Keys
                                </button>
                                <button
                                    onClick={() =>
                                        handleDownloadRedeemedKeys(
                                            redemption._id
                                        )
                                    }
                                >
                                    Download Redeemed Keys
                                </button>
                                <button onClick={() => openModal(redemption)}>
                                    Edit
                                </button>
                                {/* <button
                                    onClick={() =>
                                        handleDeleteRedemption(redemption._id)
                                    }
                                >
                                    Delete
                                </button> */}
                                <button
                                    onClick={() =>
                                        handleToggleActive(redemption._id)
                                    }
                                >
                                    {redemption.active
                                        ? 'Deactivate'
                                        : 'Activate'}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Redemptions
