import React, { useEffect, useState } from 'react'
import baxios from '../baxios.js'

import { Button } from '@mui/material'

import {
    DataGridPro,
    GridActionsCellItem,
    GridToolbar,
} from '@mui/x-data-grid-pro'

const ManageWaitlist = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [selected, setSelected] = useState([])

    function fetchData() {
        console.log('getting waitlist data')
        setLoading(true)
        baxios
            .get(`/waitlist`)
            .then((e) => {
                console.log('got waitlist data', e.data)
                setData(e.data)
            })
            .catch(console.error)
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    function handleApproveDSClick(e) {
        const emails = selected.map((e) => e.email)
        console.log(`approving ${emails} for DS`)

        baxios
            .post(
                `/waitlist/approve`,
                { emails },
                {
                    timeout: 0,
                }
            )
            .then((e) => {
                console.log(`approved ${emails} for DS`)
                fetchData()
            })
            .catch(console.error)
    }

    const waitlist =
        data?.map((e) => {
            return {
                id: e._id,
                email: e.email,
            }
        }) ?? []

    function handleSelectionModelChange(ids) {
        const selected = ids.map((id) => waitlist.find((w) => w.id === id))
        setSelected(selected)
    }

    const columns = [{ headerName: 'Email', field: 'email', flex: 1 }]

    return (
        <div>
            <h1>Manage DS Waitlist</h1>

            <Button
                variant="contained"
                color="primary"
                disabled={selected.length < 1}
                onClick={(e) => {
                    handleApproveDSClick(e)
                }}
            >
                Approve DS ({selected.length})
            </Button>

            <hr />

            <div style={{ height: '80vh' }}>
                <DataGridPro
                    checkboxSelection
                    onSelectionModelChange={handleSelectionModelChange}
                    loading={loading}
                    columns={columns}
                    rows={waitlist}
                    getRowId={(row) => row.id}
                    title="Waitlist"
                    options={{
                        pagination: true,
                    }}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                />
            </div>
        </div>
    )
}

export default ManageWaitlist
