import catbop from '../assets/images/catbop.gif'
import butler from '../assets/images/butler.jpg'
import { JsonViewer } from '@textea/json-viewer'
import { useAuth } from '../hooks/useAuth.js'

function Home() {
    const { user } = useAuth()
    return (
        <div style={{ padding: '20px' }}>
            <h1>Audialab Admin</h1>
            <img src={catbop}></img>
            <h2>
                Welcome to the <i>covoted</i> Admin panel
            </h2>
            <img src={butler}></img>
            <h2>Here is your complimentary JSON dump, oh esteemed one</h2>

            <JsonViewer
                value={user}
                enableClipboard={false}
                editable={false}
                quotesOnKeys={false}
                displayDataTypes={false}
            ></JsonViewer>
        </div>
    )
}

export default Home
