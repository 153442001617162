import React, { useState } from 'react';

const LoginForm = ({
    onSubmit,
    disableSubmit
}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit && onSubmit({
            email: email || null,
            password: password || null,
        })
        
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="email">Email:</label>
                <input 
                    type="text" 
                    id="email" 
                    value={email} 
                    onChange={e => setEmail(e.target.value)} 
                    required 
                />
            </div>
            <div>
                <label htmlFor="password">Password:</label>
                <input 
                    type="password" 
                    id="password" 
                    value={password} 
                    onChange={e => setPassword(e.target.value)} 
                    required 
                />
            </div>
            <button disabled={disableSubmit ?? false} type="submit">Login</button>
        </form>
    );
};

export default LoginForm;