import baxios from '../baxios.js'

const productHelper = {
    getAllProducts: async () => {
        console.log('Starting to fetch all products.')
        const response = await baxios.get(`/public/products`)
        console.log(
            `Fetched all products successfully. Total products fetched: ${response.data.length}`
        )
        return response.data
    },
    getProduct: async (appName) => {
        try {
            console.log(`Fetching product by appName: ${appName}`)
            if (!appName) {
                throw new Error('no appName provided to getProduct')
            }
            const response = await baxios.get(`/products/${appName}`)
            console.log(
                `Products fetched successfully for appName: ${appName}. Total products fetched: ${response.data.length}`
            )
            return response.data
        } catch (error) {
            console.error(
                `Error fetching product by appName: ${appName}`,
                error
            )
            return { error }
        }
    },
    createProduct: async (data) => {
        console.log('Attempting to create a product with data:', data)
        if (!data) {
            console.error('Error: no data provided to createProduct')
            throw new Error('no data provided to createProduct')
        }
        if (!data.name) {
            console.error('Error: no name provided to createProduct')
            throw new Error('no name provided to createProduct')
        }
        if (!data.appName) {
            console.error('Error: no appName provided to createProduct')
            throw new Error('no appName provided to createProduct')
        }

        const response = await baxios.post(`/admin/products`, {
            ...data,
        })
        console.log(`Product created successfully with ID: ${response.data.id}`)
        return response
    },
    deleteProduct: async (productId) => {
        console.log(`Attempting to delete product with ID: ${productId}`)
        if (!productId) {
            console.error('Error: no productId provided to deleteProduct')
            throw new Error('no productId provided to deleteProduct')
        }
        const response = await baxios.delete(`/products/${productId}`)
        console.log(`Product with ID: ${productId} deleted successfully.`)
        return response
    },
    updateProduct: async (productId, data) => {
        console.log(
            `Attempting to update product with ID: ${productId} using data:`,
            data
        )
        if (!productId) {
            console.error('Error: no productId provided to updateProduct')
            throw new Error('no productId provided to updateProduct')
        }
        if (!data) {
            console.error('Error: no data provided to updateProduct')
            throw new Error('no data provided to updateProduct')
        }

        try {
            const response = await baxios.patch(
                `/admin/products/${productId}`,
                data
            )
            console.log(
                `Product with ID: ${productId} updated successfully. Response data:`,
                response.data
            )
            return response.data
        } catch (error) {
            console.error(`Error updating product: ${error.message}`)
            throw error
        }
    },
    getProductById: async (productId) => {
        console.log(`Fetching product by ID: ${productId}`)
        if (!productId) {
            console.error('Error: no productId provided to getProductById')
            throw new Error('no productId provided to getProductById')
        }
        const response = await baxios.get(`/products/${productId}`)
        console.log(
            `Product fetched successfully with ID: ${productId}. Product details:`,
            response.data
        )
        return response.data
    },
    getProductSchema: async () => {
        console.log('Fetching product schema')
        try {
            const response = await baxios.get(`/products/schema`)
            console.log('Product schema fetched successfully:', response)
            return response.data
        } catch (error) {
            console.error('Error fetching product schema:', error.message)
            throw new Error('Error fetching product schema')
        }
    },
}

export default productHelper
