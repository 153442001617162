import baxios from '../baxios.js'

const redemptionHelper = {
    createRedemption: async (redemption) => {
        try {
            return baxios.post(`/admin/redemptions`, redemption)
        } catch (error) {
            console.log('error in redemptionHelper.createRedemption:', error)
            throw error
        }
    },
    // a function to get redemptions that match a filter that defaults to all redemptions
    getRedemptions: async () => {
        let filter = {}
        try {
            return baxios.get(`/admin/redemptions`, filter)
        } catch (error) {
            console.log('error in redemptionHelper.getRedemptions:', error)
            throw error
        }
    },
    // a function to delete a redemption by id
    deleteRedemption: async (id) => {
        try {
            return baxios.delete(`/admin/redemptions/${id}`)
        } catch (error) {
            console.log('error in redemptionHelper.deleteRedemption:', error)
            throw error
        }
    },
    updateRedemption: async (id, redemption) => {
        let response
        try {
            console.log('redemptionHelper.updateRedemption', redemption)
            response = await baxios.patch(
                `/admin/redemptions/${id}`,
                redemption
            )
            console.log(
                'redemptionHelper.updateRedemption response:',
                JSON.stringify(response)
            )
            return response.data
        } catch (error) {
            console.log('error in redemptionHelper.updateRedemption:', error)
            throw error.response.data.error
        }
    },
    // add licenseKeys to a redemption
    addLicenseKeys: async (id, numKeys) => {
        try {
            return baxios.post(
                `/admin/redemptions/${id}/addLicenseKeys`,
                numKeys
            )
        } catch (error) {
            console.log('error in redemptionHelper.addLicenseKeys:', error)
            throw error
        }
    },
    // update the fields of a licenseKey in a redemption with data
    updateLicenseKey: async (id, licenseKey, data) => {
        try {
            return baxios.patch(
                `/admin/redemptions/${id}/licenseKeys/${licenseKey}`,
                data
            )
        } catch (error) {
            console.log('error in redemptionHelper.updateLicenseKey:', error)
            throw error
        }
    },
    getUnredeemedKeys: async (id) => {
        try {
            return baxios.get(`/admin/redemptions/${id}/unredeemedKeys`)
        } catch (error) {
            console.log('error in redemptionHelper.getUnredeemedKeys:', error)
            throw error
        }
    },
}

export default redemptionHelper
