import React, { useState } from 'react'
import { Box, TextField, Button, Snackbar } from '@mui/material'
import { JsonViewer } from '@textea/json-viewer'
import userHelper from '../helpers/userHelper.js'
import UserEntitlements from '../components/UserEntitlements.js'

const SupportTool = () => {
    const [email, setEmail] = useState('')
    const [user, setUser] = useState(null)
    const [newEmail, setNewEmail] = useState('')
    const [toast, setToast] = useState({
        open: false,
        message: '',
        severity: 'info',
    })

    const handleEmailChange = async () => {
        try {
            const response = await userHelper.updateUser(user.email, {
                email: newEmail,
            })
            if (response.status === 200) {
                setToast({
                    open: true,
                    message: response.message,
                    severity: 'success',
                })
                fetchUser() // Fetch the latest user details after email update
            } else {
                setToast({
                    open: true,
                    message: response.error || 'Failed to update email',
                    severity: 'error',
                })
                throw new Error(response.error || 'Failed to update email')
            }
        } catch (error) {
            setToast({
                open: true,
                message: error.message,
                severity: 'error',
            })
        }
    }

    const fetchUser = async () => {
        try {
            const fetchedUser = await userHelper.getSingleUser({ email })
            if (fetchedUser) {
                setUser(fetchedUser)
                setToast({
                    open: true,
                    message: `1 user found.`,
                    severity: 'info',
                })
            } else {
                setUser(null)
                setToast({
                    open: true,
                    message: `No users found.`,
                    severity: 'info',
                })
            }
        } catch (error) {
            setUser(null)
            setToast({
                open: true,
                message: error.message,
                severity: 'error',
            })
        }
    }

    const handleEntitlementUpdate = async () => {
        const updatedUser = await userHelper.getSingleUser({ email: user.email })
        setUser(updatedUser)
    }

    return (
        <Box sx={{ p: 2 }}>
            <TextField
                label="User Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={fetchUser}
                sx={{ mb: 2 }}
            >
                Lookup User
            </Button>
            {user && (
                <Box>
                    <TextField
                        label="New Email"
                        variant="outlined"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEmailChange}
                    >
                        Change Email
                    </Button>
                </Box>
            )}
            <Snackbar
                open={toast.open}
                autoHideDuration={6000}
                onClose={() =>
                    setToast({ open: false, message: '', severity: 'info' })
                }
                message={toast.message}
                severity={toast.severity}
            />
            {user && (
                <>
                    <UserEntitlements
                        user={user}
                        onEntitlementUpdate={handleEntitlementUpdate}
                    />
                    <JsonViewer
                        value={user}
                        enableClipboard={false}
                        editable={false}
                        quotesOnKeys={false}
                        displayDataTypes={false}
                    />
                </>
            )}
        </Box>
    )
}

export default SupportTool
