import baxios from '../baxios.js'

const buildHelper = {
    getAllBuilds: async () => {
        const response = await baxios.get(`/builds`)
        return response.data
    },
    createBuild: async (data) => {
        if (!data) {
            throw new Error('no data provided to createBuild')
        }
        if (!data.downloadUrl) {
            throw new Error('no downloadUrl provided to createBuild')
        }
        if (!data.releaseNotes) {
            throw new Error('no releaseNotes provided to createBuild')
        }
        if (!data.emailBlurbHtml) {
            throw new Error('no emailBlurbHtml provided to createBuild')
        }

        let { releaseNotes, emailBlurbHtml } = data
        const response = await baxios.post(`/builds`, {
            ...data,
        })
        return response
    },
    deleteBuild: async (buildId) => {
        if (!buildId) {
            throw new Error('no buildId provided to deleteBuild')
        }
        const response = await baxios.delete(`/builds/${buildId}`)
        return response
    },
    updateBuild: async (buildId, data) => {
        if (!buildId) {
            throw new Error('no buildId provided to updateBuild')
        }
        if (!data) {
            throw new Error('no data provided to updateBuild')
        }
        const response = await baxios.patch(`/builds/${buildId}`, data)
        return response
    },
    setActiveBuild: async (buildId) => {
        if (!buildId) {
            throw new Error('no buildId provided to setActiveBuild')
        }
        // set the active build
        const response = await baxios.patch(`/builds/${buildId}/set-active`)
        return response
    },
    getActiveBuild: async () => {
        const response = await baxios.get(`/builds/get-active`)
        return response.data
    },
    uploadZipFile: async ({ zipFileName, zipFile }) => {
        // the file should be a zip file represented as a blob
        if (!zipFile) {
            throw new Error('no zipFile provided to uploadZipFile')
        }
        if (!zipFileName) {
            throw new Error('no zipFileName provided to uploadZipFile')
        }
        const formData = new FormData()
        formData.append('uploadedFile', zipFile)
        const response = await baxios.post(`/builds/upload`, formData, {
            timeout: 0, // Setting no timeout
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return response
    },
    getS3Builds: async () => {
        const response = await baxios.get(`/builds/get-all-s3-builds`)
        return response.data
    },
}

export default buildHelper
