import React, { useState } from 'react'
import { FormControl, TextField } from '@mui/material'

const CreateRedemptionForm = ({ onSubmit }) => {
    const [formData, setFormData] = useState({
        name: '',
        notes: '',
        url: '',
        contactEmail: '',
        numberOfKeys: '',
        appName: '',
    })

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        onSubmit(formData)
    }

    return (
        <form onSubmit={handleSubmit}>
            <FormControl>
                <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                />
            </FormControl>
            <FormControl>
                <TextField
                    label="Notes"
                    name="notes"
                    value={formData.notes}
                    onChange={handleChange}
                />
            </FormControl>
            <FormControl>
                <TextField
                    label="URL"
                    name="url"
                    value={formData.url}
                    onChange={handleChange}
                />
            </FormControl>
            <FormControl>
                <TextField
                    label="Contact Email"
                    name="contactEmail"
                    value={formData.contactEmail}
                    onChange={handleChange}
                />
            </FormControl>
            <FormControl>
                <TextField
                    label="App Name (e.g. 'ed', 'dp', or 'ds')"
                    name="appName"
                    value={formData.appName}
                    onChange={handleChange}
                />
            </FormControl>
            <FormControl>
                <TextField
                    label="Number of Keys"
                    name="numberOfKeys"
                    value={formData.numberOfKeys}
                    onChange={handleChange}
                />
            </FormControl>
            <button type="submit">Save Redemption</button>
        </form>
    )
}

export default CreateRedemptionForm
