import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './styles/products.css'
import { useAuth } from '../hooks/useAuth.js'
import useProducts from '../hooks/useProducts.js'
import useEditor from '../hooks/useEditor.js'
import ProductForm from '../components/ProductForm.js'
import ProductList from '../components/ProductList.js'
import productHelper from '../helpers/productHelper.js'
import stripeHelper from '../helpers/stripeHelper.js'
import JSON5 from 'json5'

const Products = () => {
    const { user } = useAuth()
    const {
        products,
        editingProduct,
        setEditingProduct,
        fetchProducts,
        handleDelete,
    } = useProducts()
    const {
        editorRef,
        editorInstanceRef,
        // hasUnsavedEdits,
        // setHasUnsavedEdits,
        updateEditorContent,
        initializeEditor,
    } = useEditor(editingProduct)

    const [isEditorView, setIsEditorView] = useState(false)
    const [collapsedSections, setCollapsedSections] = useState({
        release: true,
        prerelease: true,
        samples: true,
        stripe: true,
        entitlementConfig: true,
    })
    const [stripePriceOptions, setStripePriceOptions] = useState(
        editingProduct?.stripePriceIdOptions || []
    )
    const [activeStripePriceId, setActiveStripePriceId] = useState(
        editingProduct?.stripePriceId || ''
    )

    if (!user?.superuser) {
        return <Navigate to="/login" />
    }

    const handleToggleView = () => {
        setIsEditorView((prev) => !prev)
    }

    const handleEditorSave = async () => {
        if (!editorRef.current || !editorInstanceRef.current) {
            return
        }

        const editorContent = editorInstanceRef.current.state.doc.toString()
        let productData

        try {
            productData = JSON5.parse(editorContent)
        } catch (error) {
            toast.error('Invalid JSON content')
            return
        }

        try {
            if (editingProduct) {
                await productHelper.updateProduct(
                    editingProduct._id,
                    productData
                )
                toast.success('Product updated successfully')
            } else {
                await productHelper.createProduct(productData)
                toast.success('Product created successfully')
            }
            console.log(
                'products.js - handleEditorSave: calling setEditingProduct with data:',
                productData
            )
            setEditingProduct(productData)
            fetchProducts()
        } catch (error) {
            toast.error(`Error saving product: ${error.message}`)
        }
    }

    const toggleSection = (section) => {
        setCollapsedSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }))
    }

    const handleEdit = (product) => {
        setEditingProduct(product)
        setStripePriceOptions(product.stripePriceIdOptions || [])
        setActiveStripePriceId(product.stripePriceId || '')
        console.log('products.js - handleEdit: Editing product:', product)
    }

    const handleStripePriceChange = async (newPriceId) => {
        if (newPriceId === activeStripePriceId) return

        const confirmChange = window.confirm(
            'Are you sure you want to change the active Stripe Price ID? This will update the product with the new price ID.'
        )

        if (!confirmChange) return

        try {
            const updatedProduct = await stripeHelper.updateProduct(
                editingProduct._id,
                { stripePriceId: newPriceId }
            )
            setActiveStripePriceId(newPriceId)
            setEditingProduct(updatedProduct)
            toast.success('Stripe Price ID updated successfully')
        } catch (error) {
            toast.error(`Error updating Stripe Price ID: ${error.message}`)
        }
    }

    return (
        <div className="products-container">
            <h2 className="products-title">Products</h2>
            <button onClick={handleToggleView}>
                {isEditorView
                    ? 'Switch to Field View'
                    : 'Switch to Editor View'}
            </button>
            <ProductForm
                isEditorView={isEditorView}
                editingProduct={editingProduct}
                setEditingProduct={setEditingProduct}
                fetchProducts={fetchProducts}
                editorRef={editorRef}
                editorInstanceRef={editorInstanceRef}
                handleEditorSave={handleEditorSave}
                collapsedSections={collapsedSections}
                toggleSection={toggleSection}
                initializeEditor={initializeEditor}
                updateEditorContent={updateEditorContent}
                stripePriceOptions={stripePriceOptions}
                setStripePriceOptions={setStripePriceOptions}
                handleStripePriceChange={handleStripePriceChange}
                activeStripePriceId={activeStripePriceId}
                setActiveStripePriceId={setActiveStripePriceId}
            />
            <ProductList
                products={products}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
            <Link to="/" className="go-back-link">
                Go Back
            </Link>
            <ToastContainer />
        </div>
    )
}

export default Products
