import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import productHelper from '../helpers/productHelper.js'

const useProducts = () => {
    const [products, setProducts] = useState([])
    const [editingProduct, setEditingProduct] = useState(null)

    useEffect(() => {
        console.log('useProducts.js: useEffect - Fetching products on mount')
        fetchProducts()
    }, [])

    const fetchProducts = async () => {
        console.log('useProducts.js: fetchProducts - Fetching products')
        try {
            const fetchedProducts = await productHelper.getAllProducts()
            console.log(
                'useProducts.js: fetchProducts - Products fetched successfully',
                fetchedProducts
            )
            setProducts(fetchedProducts)
        } catch (error) {
            console.error(
                'useProducts.js: fetchProducts - Error fetching products',
                error
            )
            toast.error(`Error fetching products: ${error.message}`)
        }
    }

    const handleDelete = async (productId) => {
        console.log(
            'useProducts.js: handleDelete - Attempting to delete product with ID:',
            productId
        )
        if (window.confirm('Are you sure you want to delete this product?')) {
            try {
                await productHelper.deleteProduct(productId)
                console.log(
                    'useProducts.js: handleDelete - Product deleted successfully'
                )
                toast.success('Product deleted successfully')
                fetchProducts()
            } catch (error) {
                console.error(
                    'useProducts.js: handleDelete - Error deleting product',
                    error
                )
                toast.error(`Error deleting product: ${error.message}`)
            }
        } else {
            console.log(
                'useProducts.js: handleDelete - Deletion cancelled by user'
            )
        }
    }

    return {
        products,
        editingProduct,
        setEditingProduct,
        fetchProducts,
        handleDelete,
    }
}

export default useProducts
